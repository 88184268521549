@import "../../../presentation/shared/style/Base";
.filterCollection {
  margin-bottom: 40px;
  margin-top: 30px;
  @media (max-width: 500px) {
    text-align: center;
  }
  .nameAndBrands {
    font-size: 14px;
    color: #656565;
    width: 90%;
    margin-bottom: 30px;
    line-height: 20px;
    font-weight: 500;
    @extend %Montserrat;
  }

  .styleBorder {
    padding: 3px 5px;
    border: 1px solid #71cfc9;
    display: inline-block;
    font-size: 11px;
    margin-bottom: 25px;
    line-height: 1.3;

    .emph {
      color: #71cfc9;
    }
  }

  .contentFilter {
    margin-bottom: 19px;
    color: #656565;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 20px;

    p {
      margin-bottom: 10px;
      font-weight: bold;
    }

    .line {
      height: 3px;
      width: 100%;
      background-color: #d8d8d6;
      margin-bottom: 20px;
    }

    ul {
      li {
        color: #656565;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 20px;
        display: flex;
        cursor: pointer;
        align-items: center;

        input[type="checkbox"] {
          margin-left: 17px;
          margin-right: 6px;
        }

        .inputCheckBox {
          width: 12px;
          height: 12px;
          margin-right: 5px;
          box-sizing: border-box;
          border: 1px solid #979797;
          background: #d8d8d8;
          position: relative;

          .checked {
            width: 12px;
            height: 12px;
            top: -1px;
            position: absolute;
            left: -1px;
            background: #71cfc9;
            box-sizing: border-box;
            background: $GREEN_LIGHT;
            border: 1px solid $GREEN_LIGHT;
            color: #ffffff;

            span {
              position: absolute;
              top: 0;
              left: 2px;
              line-height: 7.5px;
              display: none;
            }
          }
        }

        input[type="checkbox"]:checked {
          background: red;
        }
      }
    }
  }

  .brandsList {
    ul {
      li {
        justify-content: left;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 20px;
      }
    }
  }

  .clearAllFilter {
    color: $BUTTON_DEFAULT;
    font-size: 11px;
    cursor: pointer;
  }

  .filter-checkbox {
    display: block;
    line-height: 1em;
    position: relative;
    padding-left: 20px;
    margin-top: 12px;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 20px;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    &:hover {
      input ~ .checkmark {
        background-color: #ccc;
      }
    }
    .checkmark {
      position: absolute;
      top: 4px;
      left: 0;
      height: 13px;
      width: 13px;
      background-color: white;
    }
  }
  .filter-checkbox input:checked ~ .checkmark {
    background-color: black;
  }

  .checkmark:after {
    content: "x";
    left: 3px;
    top: -4px;
    font-weight: 800;
    color: #fff;
    position: absolute;
    display: none;
  }

  .filter-checkbox input:checked ~ .checkmark:after {
    display: block;
  }
}

#brandPage {
  a {
    background: 0;
    border: 1px solid #71cfc9;
    width: 140px;
    height: 30px;
    color: #71cfc9;
    font-size: 12px;
    font-weight: 700;
    line-height: 17px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    cursor: pointer;
  }
}
