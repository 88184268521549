@import "../../../shared/style/Base";
.no-collection {
  width: 230px;
  text-align: center;
  color: #666;
  font-weight: 700;
  padding: 30px 20px;
  line-height: 20px;
  font-size: 16px;
  margin-top: 20px;
  p {
    font-weight: 400;
    margin-top: 10px;
    font-size: 14px;
  }
}

.select-collection {
  padding-bottom: 150px;
  display: flex;
  justify-content: center;
  max-width: 900px;
  flex-wrap: wrap;
  margin: 0 auto;
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
  .collection-box {
    width: 200px;
    margin: 10px 10px;
    padding-bottom: 22.5px;
    border: 1px solid #ccc;
    text-align: center;
    cursor: pointer;
    @media (max-width: 500px) {
      margin-bottom: 20px;
    }

    .slider-wrapper {
      width: 200px;

      .image-wrapper {
        height: 300px;
        width: 200px;
        padding-bottom: 5px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .swiper-pagination {
        color: #666;
        @extend %Montserrat;

        font-size: 13px;
        font-weight: 400;
      }
      .swiper-button-disabled {
        opacity: 0;
      }
      .swiper-button-next {
        background-image: url("../images/right_arrow.png");
      }
      .swiper-button-prev {
        background-image: url("../images/left_arrow.png");
      }
      .swiper-button-next,
      .swiper-button-prev {
        top: auto;
        bottom: 14px;
        background-size: 24px 9px;
        width: 24px;
        height: 9px;
      }
    }
    .collection-name {
      margin-top: 10px;
      color: #666;
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;

      .name-container {
        height: 30px;
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .button-container {
        display: flex;
        justify-content: center;
        a {
          letter-spacing: 2px;
        }
        button {
          margin-top: 15px;
        }
      }
    }
    .button {
      line-height: 28px;
      height: 28px;
      margin-top: 15px;
    }
  }
}
