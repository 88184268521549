#our-retailers-slider {
    width: 100%;

    @media (max-width: 768px){
      margin-top: 30px;
    }

    .slick-arrow{

      @media(max-width: 500px){
        display: block !important;
      }
    }

    .slick-prev {
        left: -100px;
        @media (max-width: 768px){
          left: 0;
          z-index: 9999 !important;
        }
    }

    .slick-next {
        right: -100px;
        @media (max-width: 768px){
          right: 0;
        }
    }

}
#our-retailers-items{
  @media (max-width: 768px){
    overflow: hidden;
    margin-left: 20px;
    padding-right: 30px;
  }
  .slick-list {
    @media (max-width: 768px){
      overflow: visible;
      border-right: 0;
    }

  }
  .slick-slide{
    box-sizing: border-box;
  }
  .slide{
    .retailer-image-wrapper{
        height: 100%;
        width: 100%;
        @media (max-width: 768px){
          padding: 10px;
          width: 60%;
        }
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
      @media (max-width: 768px){
        width: 100%;
        max-height: 169px;
      }
    }
  }
  .slick-slider{
    @media (max-width: 768px){
      margin-right: 30px;
    }

  }
  .slick-slider:first-child
  .retailer {
    width: 188px;
    height: 200px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;


    @media (max-width: 768px){
      height: 170px;
      justify-content: flex-start;
    }
  }

  .retailer + .retailer{
    // border-bottom: 1px solid #979797;
  }
}
