@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none; }

button {
  border: none;
  outline: none; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  font-family: "PolySans-Slim";
  background: #ffffff; }

.containerLineSheet h2 {
  font-family: "PolySans-Slim", sans-serif; }

@media (max-width: 768px) {
  html, body {
    max-width: 768px;
    width: 100%;
    overflow-x: hidden; } }

/* @media (max-width: 1366px) {
    %CENTER_COMPONENT {
        width: 90%;
        margin: 0 auto;
    }

    %CENTER_COMPONENT_SMALL {
        width: 80%;
        margin: 0 auto;
    }
} */
.variation-tooltip {
  color: #6d6d6d;
  font-weight: bold;
  box-shadow: 0px 2px 6px #99999940;
  border-radius: 5px;
  padding: 6px 10px; }
  @media (max-width: 768px) {
    .variation-tooltip {
      max-width: 50px; } }

.enlargedImage {
  object-fit: contain; }

.enlargedImageContainer {
  background: #FFF; }

.containerLineSheet {
  width: 1000px;
  margin-top: 80px;
  position: relative;
  overflow: hidden;
  padding-bottom: 40px; }
  @media (max-width: 768px) {
    .containerLineSheet {
      width: 100%;
      margin-top: 0; } }
  .containerLineSheet .contentSlider {
    width: 930px;
    margin-left: 35px; }
  .containerLineSheet .slick-dots {
    height: 50px;
    bottom: -60px !important; }
    .containerLineSheet .slick-dots li {
      width: 11px;
      height: 11px;
      border: 2px solid #D8D8D8;
      border-radius: 100%;
      box-sizing: border-box; }
      .containerLineSheet .slick-dots li button:before {
        display: none; }
      .containerLineSheet .slick-dots li.slick-active {
        background: #D8D8D8; }
  .containerLineSheet h2 {
    margin: 40px 0;
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
    color: #666; }
    .containerLineSheet h2:before {
      content: "";
      position: absolute;
      left: 35px;
      right: 64%;
      background: #000000;
      height: 2px;
      margin-top: 8px; }
      @media (max-width: 768px) {
        .containerLineSheet h2:before {
          left: 37px;
          right: 75%; } }
    .containerLineSheet h2:after {
      content: "";
      position: absolute;
      left: 64%;
      right: 55px;
      background: #000000;
      height: 2px;
      margin-top: 8px; }
      @media (max-width: 768px) {
        .containerLineSheet h2:after {
          left: 75%;
          right: 37px; } }

.centerInfoProduct {
  width: 950px;
  margin: 30px auto 50px auto; }
  @media (max-width: 768px) {
    .centerInfoProduct {
      width: 100%; } }
  .centerInfoProduct .mainInfoProduct {
    display: flex;
    justify-content: space-between;
    margin-top: 50px; }
    @media (max-width: 768px) {
      .centerInfoProduct .mainInfoProduct {
        flex-direction: column;
        align-items: center; } }
    .centerInfoProduct .mainInfoProduct .no-product-image {
      object-fit: contain; }
    .centerInfoProduct .mainInfoProduct .btnAddToCart {
      margin-top: 20px;
      cursor: pointer; }
    @media (max-width: 768px) {
      .centerInfoProduct .mainInfoProduct .thumbs {
        display: flex; } }
    .centerInfoProduct .mainInfoProduct .thumb {
      width: 61px;
      height: 61px;
      box-sizing: border-box;
      cursor: pointer;
      margin-bottom: 10px; }
      .centerInfoProduct .mainInfoProduct .thumb img {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: 1px solid #cccccc; }
    .centerInfoProduct .mainInfoProduct .image {
      width: 540px;
      box-sizing: border-box;
      border: 1px solid #cccccc;
      z-index: 1; }
      @media (max-width: 768px) {
        .centerInfoProduct .mainInfoProduct .image {
          width: 80%; } }
      .centerInfoProduct .mainInfoProduct .image img {
        width: 100%;
        height: 100%; }
    .centerInfoProduct .mainInfoProduct .infoProduct {
      width: 312px;
      box-sizing: border-box; }
      @media (max-width: 768px) {
        .centerInfoProduct .mainInfoProduct .infoProduct {
          margin-top: 40px; } }
      .centerInfoProduct .mainInfoProduct .infoProduct .nameAndRef {
        border-bottom: 4px solid #d8d8d8;
        margin-bottom: 15px; }
        .centerInfoProduct .mainInfoProduct .infoProduct .nameAndRef h4 {
          font-size: 25px;
          margin-bottom: 5px;
          color: #6d6d6d;
          text-transform: uppercase; }
        .centerInfoProduct .mainInfoProduct .infoProduct .nameAndRef p {
          font-size: 18px;
          margin-bottom: 15px;
          color: #6d6d6d;
          word-wrap: break-word;
          word-break: break-all; }
      .centerInfoProduct .mainInfoProduct .infoProduct .description {
        margin-bottom: 15px;
        font-size: 12px;
        font-weight: 300;
        text-align: justify;
        line-height: 16px;
        color: #6d6d6d; }
      .centerInfoProduct .mainInfoProduct .infoProduct .pricesProducts {
        font-size: 14px;
        color: #656565; }
        .centerInfoProduct .mainInfoProduct .infoProduct .pricesProducts p {
          margin-bottom: 5px; }
          .centerInfoProduct .mainInfoProduct .infoProduct .pricesProducts p span {
            font-weight: bold;
            color: #6d6d6d; }
      .centerInfoProduct .mainInfoProduct .infoProduct .variationsProduct .titleVariation {
        margin: 25px 0 10px 0;
        font-size: 14px;
        font-weight: 700;
        color: #6d6d6d; }
      .centerInfoProduct .mainInfoProduct .infoProduct .variationsProduct .contentVariation ul {
        display: flex;
        flex-wrap: wrap; }
        .centerInfoProduct .mainInfoProduct .infoProduct .variationsProduct .contentVariation ul li {
          margin: 0 5px 5px 0;
          position: relative;
          width: 40px;
          height: 40px;
          outline: 0;
          border: 1px solid #ccc;
          cursor: pointer;
          background-size: cover; }
          .centerInfoProduct .mainInfoProduct .infoProduct .variationsProduct .contentVariation ul li .colorListNoTexture {
            position: absolute;
            display: block;
            top: 0;
            border-left: 40px solid transparent;
            border-bottom: 40px solid transparent; }
          .centerInfoProduct .mainInfoProduct .infoProduct .variationsProduct .contentVariation ul li.activeVariation:after {
            content: "✓";
            top: 0;
            left: 0;
            position: absolute;
            width: 38px;
            height: 38px;
            font-size: 16px;
            line-height: 38px;
            text-align: center;
            color: #fff;
            text-shadow: -1px -1px 1px rgba(120, 120, 120, 0.8), 1px -1px 1px rgba(120, 120, 120, 0.8), -1px 1px 1px rgba(120, 120, 120, 0.8), 1px 1px 1px rgba(120, 120, 120, 0.8); }
      .centerInfoProduct .mainInfoProduct .infoProduct .containerSizes .titleContainerSizes {
        margin: 25px 0 10px 0;
        font-size: 14px;
        font-weight: 700;
        color: #6d6d6d; }
      .centerInfoProduct .mainInfoProduct .infoProduct .containerSizes ul {
        display: flex;
        flex-wrap: wrap; }
        .centerInfoProduct .mainInfoProduct .infoProduct .containerSizes ul li {
          margin: 0 5px 5px 0;
          padding: 0 10px;
          min-width: 40px;
          height: 40px;
          box-sizing: border-box;
          font-weight: 300;
          line-height: 40px;
          text-align: center;
          background-color: #fdfdfd;
          border: 1px solid #ccc;
          font-size: 12px; }
          .centerInfoProduct .mainInfoProduct .infoProduct .containerSizes ul li.viewSize {
            cursor: pointer; }
      .centerInfoProduct .mainInfoProduct .infoProduct .containerDetails .titleDetails {
        margin: 25px 0 10px 0;
        font-size: 14px;
        font-weight: 700;
        color: #6d6d6d;
        text-transform: uppercase; }
      .centerInfoProduct .mainInfoProduct .infoProduct .containerDetails ul {
        list-style: disc;
        margin-left: 15px; }
        .centerInfoProduct .mainInfoProduct .infoProduct .containerDetails ul li {
          font-size: 12px;
          color: #6d6d6d;
          margin: 10px 0;
          line-height: 15px;
          font-weight: 300; }
      .centerInfoProduct .mainInfoProduct .infoProduct .btnTransparent {
        border: 1px solid #dcdcdc;
        background: 0;
        width: 201px;
        outline: 0;
        line-height: 33px;
        font-size: 12px;
        text-transform: uppercase;
        padding: 0 10px;
        color: #6d6d6d;
        cursor: pointer;
        font-weight: 300; }
        .centerInfoProduct .mainInfoProduct .infoProduct .btnTransparent #termsAndAboutRatings {
          padding-bottom: 0px; }
          .centerInfoProduct .mainInfoProduct .infoProduct .btnTransparent #termsAndAboutRatings ul li button {
            font-size: 12px;
            text-transform: uppercase;
            padding: 0 10px;
            color: #6d6d6d;
            cursor: pointer;
            font-weight: 300; }
  .centerInfoProduct .topInfoProduct {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 768px) {
      .centerInfoProduct .topInfoProduct {
        flex-direction: column;
        align-items: center; } }
    .centerInfoProduct .topInfoProduct .nameProduct {
      text-transform: uppercase;
      color: #999;
      font-size: 14px;
      font-weight: bold; }
    .centerInfoProduct .topInfoProduct .breadcrumbProduct {
      color: #999;
      font-size: 16px;
      text-transform: lowercase; }
      .centerInfoProduct .topInfoProduct .breadcrumbProduct a {
        color: #999;
        font-size: 14px;
        text-transform: lowercase; }
        .centerInfoProduct .topInfoProduct .breadcrumbProduct a.active {
          font-weight: bold; }

.blocksProducts.blocksProductsInfo {
  width: 222px !important;
  min-height: 449px;
  margin-right: 0;
  margin-bottom: 13px;
  border: 1px solid #ccc;
  box-sizing: border-box; }
  .blocksProducts.blocksProductsInfo .topBlockProducts {
    height: 294px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
    .blocksProducts.blocksProductsInfo .topBlockProducts a {
      height: 100%;
      width: 100%; }
    .blocksProducts.blocksProductsInfo .topBlockProducts img {
      height: auto;
      width: 100%; }
    .blocksProducts.blocksProductsInfo .topBlockProducts .first-image-wrapper {
      height: 100%;
      display: flex;
      align-items: center; }
    .blocksProducts.blocksProductsInfo .topBlockProducts .hover-image-wrapper {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white; }
      .blocksProducts.blocksProductsInfo .topBlockProducts .hover-image-wrapper img {
        height: auto; }
    .blocksProducts.blocksProductsInfo .topBlockProducts:hover .hover-image-wrapper {
      opacity: 1; }
  .blocksProducts.blocksProductsInfo .mainProduct {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    color: #656565; }
    .blocksProducts.blocksProductsInfo .mainProduct .nameBrand {
      width: 90%;
      text-transform: uppercase;
      text-align: center;
      font-size: 11px;
      color: #656565;
      margin: 25px 0 10px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .blocksProducts.blocksProductsInfo .mainProduct .nameBrandCollection {
      min-width: 50px;
      font-size: 12px;
      line-height: 20px;
      color: #656565;
      margin: 10px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .blocksProducts.blocksProductsInfo .mainProduct .nameProduct {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 11px;
      color: #656565;
      margin: 10px 0; }
    .blocksProducts.blocksProductsInfo .mainProduct .variations {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 0 0 17px 0;
      flex-wrap: wrap; }
      .blocksProducts.blocksProductsInfo .mainProduct .variations li {
        width: 15px;
        height: 15px;
        border: 1px solid #ccc;
        margin: 10px 0 0 4px;
        background-size: cover;
        position: relative; }
        .blocksProducts.blocksProductsInfo .mainProduct .variations li .colorListNoTexture {
          position: absolute;
          display: block;
          top: 0;
          border-left: 15px solid transparent;
          border-bottom: 15px solid transparent; }
        .blocksProducts.blocksProductsInfo .mainProduct .variations li:first-child {
          margin-left: 0; }
    .blocksProducts.blocksProductsInfo .mainProduct .prices {
      font-size: 11px;
      color: #656565;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 14px; }
      .blocksProducts.blocksProductsInfo .mainProduct .prices p {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 5px; }
    .blocksProducts.blocksProductsInfo .mainProduct .buttonProduct {
      margin: 10px 0 20px 0;
      padding: 8px 20px;
      background: transparent;
      border: 2px solid #666;
      color: #666;
      font-size: 10px;
      cursor: pointer; }
    .blocksProducts.blocksProductsInfo .mainProduct .add-to-cart-btn {
      width: auto; }

.bold {
  font-weight: 800; }

.youtube-container {
  width: 100%;
  height: 100%; }
