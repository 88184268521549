@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none; }

button {
  border: none;
  outline: none; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  font-family: "PolySans-Slim";
  background: #ffffff; }

@media (max-width: 768px) {
  html, body {
    max-width: 768px;
    width: 100%;
    overflow-x: hidden; } }

/* @media (max-width: 1366px) {
    %CENTER_COMPONENT {
        width: 90%;
        margin: 0 auto;
    }

    %CENTER_COMPONENT_SMALL {
        width: 80%;
        margin: 0 auto;
    }
} */
.variation-tooltip {
  color: #6d6d6d;
  font-weight: bold;
  box-shadow: 0px 2px 6px #99999940;
  border-radius: 5px;
  padding: 6px 10px; }
  @media (max-width: 768px) {
    .variation-tooltip {
      max-width: 50px; } }

.lightBox {
  position: fixed;
  top: 0;
  z-index: 10; }
  .lightBox .bgLightBox {
    background: #000000;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    opacity: 0.8;
    z-index: 12; }
  .lightBox .containerLightBox {
    padding: 20px;
    background: #ffffff;
    position: fixed;
    width: 350px;
    height: 385px;
    margin: -190px 0 0 -180px;
    top: 50%;
    left: 50%;
    z-index: 14;
    box-shadow: 2px 3px 11px 0px rgba(0, 0, 0, 0.35); }
    .lightBox .containerLightBox .closed {
      font-size: 20px;
      color: black;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer; }

@media (max-width: 768px) {
  .lightBox .containerLightBox {
    padding: 10px;
    width: auto;
    height: auto;
    margin: 0px;
    left: 15px;
    right: 15px;
    top: 20px; }
    .lightBox .containerLightBox .closed {
      padding: 20px;
      top: 0;
      right: 0; } }
