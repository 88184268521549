@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none; }

button {
  border: none;
  outline: none; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  font-family: "PolySans-Slim";
  background: #ffffff; }

@media (max-width: 768px) {
  html, body {
    max-width: 768px;
    width: 100%;
    overflow-x: hidden; } }

/* @media (max-width: 1366px) {
    %CENTER_COMPONENT {
        width: 90%;
        margin: 0 auto;
    }

    %CENTER_COMPONENT_SMALL {
        width: 80%;
        margin: 0 auto;
    }
} */
.variation-tooltip {
  color: #6d6d6d;
  font-weight: bold;
  box-shadow: 0px 2px 6px #99999940;
  border-radius: 5px;
  padding: 6px 10px; }
  @media (max-width: 768px) {
    .variation-tooltip {
      max-width: 50px; } }

.boxComponent {
  display: flex;
  position: relative;
  justify-content: center;
  flex-wrap: wrap;
  width: calc(100% - 15px);
  margin: 0 14px 31px 0;
  box-sizing: border-box;
  transition: all linear .4s; }
  .boxComponent img {
    width: 284px;
    height: 285px;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    transition: all linear .4s; }
    @media (max-width: 500px) {
      .boxComponent img {
        width: 100px !important;
        height: auto; } }
  .boxComponent .titleBoxComponent {
    font-size: 12px;
    width: 200px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #ffffff;
    position: absolute;
    bottom: -13px;
    left: 50%;
    margin-left: -100px;
    flex-wrap: wrap;
    transition: 0.5s;
    font-weight: 600;
    font-family: "PolySans-Slim";
    letter-spacing: 2px;
    box-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.14);
    opacity: 1;
    transition-delay: .3s; }
    .boxComponent .titleBoxComponent a {
      height: 26px;
      line-height: 26px;
      box-sizing: border-box;
      border-radius: 4px; }
      .boxComponent .titleBoxComponent a span {
        color: #000000; }
    .boxComponent .titleBoxComponent:before {
      content: " ";
      width: 100%;
      height: 80%;
      position: absolute;
      top: 0;
      left: 0;
      border-bottom: none;
      z-index: -1; }
    .boxComponent .titleBoxComponent .buttonBox {
      transition-delay: .3s;
      opacity: 0; }
    .boxComponent .titleBoxComponent span {
      width: 100%;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 12px;
      font-weight: 600;
      margin: 14px 5px; }
    @media (max-width: 768px) {
      .boxComponent .titleBoxComponent {
        bottom: -40px;
        box-shadow: none; } }
  .boxComponent:hover .titleBoxComponent {
    height: 60px;
    bottom: -1px; }
    .boxComponent:hover .titleBoxComponent .buttonBox {
      transition-delay: .3s;
      opacity: 1; }
  .boxComponent.highlightBox {
    width: calc(100% - 15px);
    margin: 0 15px 18px 0; }
  .boxComponent.effect {
    opacity: 0.3;
    height: 285px;
    padding: 30px;
    pointer-events: none;
    box-sizing: border-box; }
    .boxComponent.effect a {
      display: flex;
      justify-content: center;
      align-items: center; }
      .boxComponent.effect a img {
        width: 224px;
        height: 225px; }
    .boxComponent.effect .titleBoxComponent {
      opacity: 0; }
  @media (max-width: 768px) {
    .boxComponent {
      margin: 0 14px 50px 0; } }
