@import "../../shared/style/Base.scss";

.subContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .mainBox {
        margin-top: 160px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 1200px;
        width: 90%;
        height: 700px;
        background-image: url('../images/zabor.png');
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 50px;
    
        .boxTitle,
        .boxText {
            color: $FONT-COLOR;
            @extend %Montserrat;
        }
    
        .boxTitle {
            font-size: 2.5em;
            margin: 25px 0;
            text-transform: uppercase;
            line-height: 1.5;
            text-align: center;
        }
    
        .boxText {
            font-size: 1.8em;
        }
    }
}

.textContainer {
    margin: 0 auto;
    max-width: 900px;
    padding-bottom: 150px;
    
    .emphasized {
        font-weight: bold;
        font-style: italic;
    }

    .quoteCall {
        margin: 50px 0;
    }

    .quoteContent {
        display: flex;
        align-items: center;
        margin-top: 40px;
    
        .quoteText {
            font-weight: 700;
            font-size: 1.3em;
            font-style: italic;
            margin-right: 20px;
            float: left;
        }

        .mapImage {
            width: 280px;
            height: 280px;
        }
    }

    p, span {
        max-width: 900px;
    }

    p {
        margin: 30px 0;
        line-height: 1.5;
        text-align: justify;
    }

    .clothesImage {
        width: 430px;
        height: 450px;
        float: left;
        margin: 0 35px 35px 0;
    }
}