@import "../../../../../shared/style/Base.scss";

#SecondHeader {
     
    margin-top: $marginTopHero;
    position: relative;
    background: rgba(255,255,255,1);
    background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 96%, rgba(237,237,237,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(96%, rgba(255,255,255,1)), color-stop(100%, rgba(237,237,237,1)));
    background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 96%, rgba(237,237,237,1) 100%);
    background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 96%, rgba(237,237,237,1) 100%);
    background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 96%, rgba(237,237,237,1) 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 96%, rgba(237,237,237,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ededed', GradientType=0 );
    box-sizing: border-box;
    display: flex;
     
    height: 640px;
    display: flex;
    align-items: center;
    
    @media (max-width: 1360px) {
        height: 600px;
    }
    @media (max-width: 1200px) {
        height: 550px;
    }
    @media (max-width: 1024px) {
        height: 500px;
    }

    @media (max-width: 768px){
        margin-top: 58px;
    }

    .texture2 {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        background-size: cover;
        background-position: center;
        box-shadow: inset 0px -45px 27px -28px rgba(0,0,0,0.15);
    }

    .logoAndText {
        width: 570px;
        z-index: 2;
        margin-left: 250px;

        @media (max-width: 1650px) {
            margin-left: 200px;
        }

        @media (max-width: 1480px) {
            margin-left: 160px;
        }

        @media (max-width: 1360px) {
            margin-left: 130px;
        }

        @media (max-width: 1200px) {
            margin-left: 100px;
        }

        @media (max-width: 768px) {
            display: none;
        }

        img {
            margin-bottom: 30px;
            width: 420px;

            @media (max-width: 1680px) {
                width: 400px;
            }
            @media (max-width: 1550px) {
                width: 365px;
            }
            @media (max-width: 1366px) {
                width: 315px;
            }
            @media (max-width: 1200px) {
                width: 270px;
            }
            @media (max-width: 1100px) {
                width: 250px;
            }
            @media (max-width: 768px){
                width: 130px;
            }
        }

        p {
            width: 568px;
            color: #324751;
            font-family: $FONT;
            font-weight: 300;
            letter-spacing: -0.04px;
            line-height: 43px;
            text-shadow: 0 2px 4px 0 #FBFBFB;
            font-size: 32px;

            @media (max-width: 1680px) {
                font-size: 28px;
                width: 475px;
            }
            @media (max-width: 1550px) {
                font-size: 26px;
                width: 445px;
            }
            @media (max-width: 1366px) {
                font-size: 24px;
                width: 420px;
            }
            @media (max-width: 1200px) {
                font-size: 22px;
                width: 390px;
            }
            @media (max-width: 1100px) {
                font-size: 20px;
                width: 375px;
            }
            @media (max-width: 768px){
                font-size: 12px;
                width: 135px;
                line-height: 16px;
            }
        }

        .buttons {
            margin-top: 45px;
            display: flex;
            @media (max-width: 768px){
                display: none;
            }
            .link {
                font-size: 14px;
                font-weight: normal;
                font-family: $FONT-POLY;
                text-transform: uppercase;
                height: 40px;
                width: 130px;
                color: #fff;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #272626;
                border-radius: 30px;

                &.login{
                    background-color: transparent;
                    box-shadow: none;
                    width: 144px;
                    padding-bottom: 15px;
                    color: black;
                }
                &.register {
                    color: #FFFFFF;
                    box-shadow: 0 1px 10px 0 rgba(0,0,0,0.25);
                    @extend %BUTTON_BLACK;
                }
            }

            @media (max-width: 1680px) {
                margin-top: 40px;

                .link {
                    width: 180px;
                    height: 55px;
                }
            }
            @media (max-width: 1366px) {
                margin-top: 35px;

                .link {
                    width: 160px;
                    height: 50px;
                }
            }
            @media (max-width: 1200px) {
                margin-top: 30px;

                .link {
                    width: 140px;
                    height: 45px;
                }
            }
        }
    }

    .headerImages {
        width: 50%;
        height: 100%;
        z-index: 6;
        display: flex;
        align-items: center;
        overflow: hidden;

        .image {
            width: 780px;
            height: 100%;
            z-index: 5;
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            @media (max-width: 1366px) {
                width: 580px;
            }

            img {
                max-height: 480px;
                max-width: 620px;
                box-shadow: 35px 25px 30px 1px rgba(0,0,0,0.17);
                position: relative;

                @media (max-width: 1920px) {
                    max-height: 480px;
                    max-width: 620px;                
                }
                @media (max-width: 1680px) {
                    max-height: 480px;
                    max-width: 620px;
                }
                @media (max-width: 1366px) {
                    max-width: 520px;
                }
                @media (max-width: 1200px) {
                    max-width: 620px;
                }
            }
        }

        .trees {
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            flex-direction: column;
            width: 500px;
            height: 100%;
            z-index: -1;
            overflow: hidden;

            .tree-one {
                position: absolute;
                width: 100%;
                height: 100%;
                bottom: 20%;
                left: 75px;
                z-index: 3;
                background-size: cover;
                background-repeat: no-repeat;
                @media (max-width: 768px){
                  height: 290px;
                  left: 50px;
                  bottom: auto;
                  top: -43px;
                  background-repeat: no-repeat;
                  background-size: auto 250px;
                  background-position: right;
                }
            }

            .tree-two {
                width: 90%;
                height: 60%;
                background-size: cover;
                background-repeat: no-repeat;
                position: absolute;
                bottom: -5%;
                left: 150px;
                z-index: 2;				
                @media (max-width: 768px){
                  background-position: right;
                  background-size: auto 160px;
                  width: 300px;
                  height: 160px;
                  bottom: auto;
                  top: 161px;
                  left: 74px;
                }
            }
        }
    }

    .secondHeaderImages {
        width: 60%;
        height: 100%;
        z-index: 3;
        position: relative;
        right: 0;

        .mask-img {
            display: block;
            background-repeat: no-repeat;
            position: relative;
            max-width: 100%;
            height: 100%;
            width: 100%;
            background-attachment: fixed;
            background-size: 60%;
            background-position: right 80px;
        }

        

        .object-mask {
            /* mask-image: url(./palmeira.svg);
            mask-repeat: no-repeat;
            mask-size: 89%;
            mask-position: right;*/
            position: relative;
            right: -90px;
            top: -70px;

            @media (max-width: 1840px) {
                mask-size: 95%;
                top: -73px;
            }
            @media (max-width: 1770px) {
                mask-size: 97%;
                right: -60px;
                top: -80px;
            }
            @media (max-width: 1710px) {
                mask-size: 92%;
                right: -60px;
                top: -50px;
            }
            @media (max-width: 1600px) {
                mask-size: 93%;
                right: -60px;
                top: -40px;
            }
            @media (max-width: 1550px) {
                mask-size: 95%;
                right: -60px;
                top: -70px;
            }
            @media (max-width: 1480px) {
                right: -60px;
                top: -80px;
            }
            @media (max-width: 1400px) {
                mask-size: 98%;
                right: -60px;
                top: -87px;
            }
            @media (max-width: 1366px) {
                mask-size: 95%;
                right: -60px;
                top: -40px;
            }
            @media (max-width: 1310px) {
                mask-size: 96%;
                right: -60px;
                top: -45px;
            }
            @media (max-width: 1260px) {
                mask-size: 98%;
                right: -60px;
                top: -60px;
            }
            @media (max-width: 1210px) {
                mask-size: 97%;
                right: -50px;
                top: -45px;
            }
            @media (max-width: 1165px) {
                mask-size: 100%;
                right: -50px;
                top: -50px;
            }
            @media (max-width: 1100px) {
                mask-size: 100%;
                right: -40px;
                top: -75px;
            }
            @media (max-width: 1024px) {
                mask-size: 100%;
                right: -40px;
                top: -60px;
            }
        }
    }
}
