@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none; }

button {
  border: none;
  outline: none; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  font-family: "PolySans-Slim";
  background: #ffffff; }

@media (max-width: 768px) {
  html, body {
    max-width: 768px;
    width: 100%;
    overflow-x: hidden; } }

/* @media (max-width: 1366px) {
    %CENTER_COMPONENT {
        width: 90%;
        margin: 0 auto;
    }

    %CENTER_COMPONENT_SMALL {
        width: 80%;
        margin: 0 auto;
    }
} */
.variation-tooltip {
  color: #6d6d6d;
  font-weight: bold;
  box-shadow: 0px 2px 6px #99999940;
  border-radius: 5px;
  padding: 6px 10px; }
  @media (max-width: 768px) {
    .variation-tooltip {
      max-width: 50px; } }

.menu-component {
  z-index: 99999;
  position: relative; }
  .menu-component .logo-wrapper-mobile {
    display: none; }

#navItems {
  height: 60px;
  width: 100%;
  background: white;
  top: 0;
  position: fixed;
  z-index: 10; }
  #navItems .MenuResponsive {
    display: none; }
  #navItems .centerNav {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between; }
    #navItems .centerNav .left,
    #navItems .centerNav .right {
      display: flex;
      align-items: center; }
    #navItems .centerNav .right .menu-orders {
      margin-left: 30px; }
      #navItems .centerNav .right .menu-orders a {
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 20px;
        color: #a7a9af;
        text-decoration: none; }
    #navItems .centerNav ul {
      height: 60px;
      display: flex;
      align-items: center; }
      #navItems .centerNav ul li {
        color: #a7a9af;
        padding: 0 10px;
        height: 50px;
        line-height: 50px;
        box-sizing: border-box; }
        #navItems .centerNav ul li span {
          text-transform: capitalize; }
        #navItems .centerNav ul li:hover {
          color: #50545e; }
        #navItems .centerNav ul li.active {
          color: #50545e; }
        #navItems .centerNav ul li a {
          color: #50545e;
          text-transform: capitalize; }
      #navItems .centerNav ul.mnSeller {
        align-items: flex-start;
        justify-content: flex-start; }
  #navItems .logo-wrapper {
    width: 120px;
    margin-left: 100px;
    display: flex;
    justify-content: center;
    align-items: center; }
    #navItems .logo-wrapper img {
      width: 120px; }
  #navItems .centerNav li.dropdown-link:hover {
    font-weight: bold;
    background: #71cfc9;
    cursor: default;
    display: flex; }
    @media (min-width: 769px) {
      #navItems .centerNav li.dropdown-link:hover .dropdown-wrapper {
        font-weight: normal;
        display: flex; } }
  #navItems .dropdown-wrapper {
    font-family: "PolySans-Slim";
    position: absolute;
    width: 200px;
    top: 50px;
    display: none;
    z-index: 999;
    margin-left: -60px;
    font-size: 14px; }
    @media (min-width: 769px) {
      #navItems .dropdown-wrapper:hover {
        display: flex; } }
    #navItems .dropdown-wrapper .dropdown {
      display: flex;
      width: 100%;
      padding: 0 0 20px 0;
      background: #ffffff;
      box-sizing: border-box;
      font-size: 16px;
      background: #71cfc9;
      color: #fff;
      align-items: center;
      flex-direction: column; }
    #navItems .dropdown-wrapper p {
      font-size: 12px;
      margin-left: 19px; }
    #navItems .dropdown-wrapper .separator {
      margin: 10px auto 10px auto;
      height: 2px;
      width: 50px;
      background: #fff;
      opacity: 0.8; }
    #navItems .dropdown-wrapper .dropdownSettings-menu {
      display: flex;
      width: 100%;
      flex-direction: column;
      flex: 1;
      height: 100%; }
      #navItems .dropdown-wrapper .dropdownSettings-menu li {
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        line-height: 40px; }
        #navItems .dropdown-wrapper .dropdownSettings-menu li a {
          color: #fff; }
        #navItems .dropdown-wrapper .dropdownSettings-menu li:hover {
          background: #81d4cf; }
  @media (max-width: 768px) {
    #navItems .dropdown-wrapper {
      position: relative;
      width: 100%;
      top: 0;
      margin-left: 0;
      flex-direction: column;
      align-items: center; }
      #navItems .dropdown-wrapper .dropdown {
        background: #81d4cf; }
        #navItems .dropdown-wrapper .dropdown .dropdownSettings-menu {
          padding-bottom: 0; }
    #navItems .centerNav li.dropdown-link {
      height: auto; }
    #navItems .centerNav li.dropdown-link:hover {
      background: #81d4cf; }
    #navItems .centerNav li.dropdown-link .dropdown-wrapper {
      padding-bottom: 15px; }
      #navItems .centerNav li.dropdown-link .dropdown-wrapper .close {
        height: 20px;
        width: 20px;
        border-radius: 10px;
        border: 2px solid #fff;
        background: transparent;
        font-size: 14px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center; }
    #navItems .centerNav li.dropdown-link .openedDropdown {
      display: flex;
      flex-direction: column;
      align-items: center; } }
  #navItems #LoginMobile {
    display: none; }
  #navItems #closeMenu {
    display: none; }

@media (max-width: 768px) {
  .menu-component {
    position: relative;
    background: white;
    height: 60px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
    display: flex;
    justify-content: center; }
    .menu-component .MenuResponsive {
      display: block;
      position: fixed;
      top: 0px;
      left: 0px;
      width: 25px;
      top: 10px;
      left: 0px;
      cursor: pointer;
      padding: 10px; }
      .menu-component .MenuResponsive span {
        display: block;
        width: 100%;
        height: 3px;
        background-color: #666;
        margin-bottom: 3px; }
    .menu-component .logo-wrapper-mobile {
      display: block;
      width: 120px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .menu-component .logo-wrapper-mobile img {
        width: 120px;
        height: 50.7px; }
  #navItems {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    overflow: auto;
    top: 0;
    left: 0;
    display: none;
    background-color: #f1edea; }
    #navItems .left {
      display: none !important; }
    #navItems #closeMenu {
      display: block;
      position: relative;
      width: 21px;
      height: 21px;
      color: #666666;
      padding: 13px;
      top: 6px;
      margin-bottom: 76px;
      font-size: 30px;
      font-weight: 600;
      line-height: 21px; }
    #navItems #LoginMobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: relative;
      padding-top: 55px; }
      #navItems #LoginMobile:before {
        content: "";
        display: block;
        width: 74px;
        height: 2px;
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -37px; }
      #navItems #LoginMobile p {
        display: inline-block;
        margin: 0 auto;
        height: 34px;
        line-height: 34px;
        border: 2px solid #ffffff;
        border-radius: 8px;
        box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.12);
        font-size: 14px;
        font-weight: bold;
        padding: 0 44px;
        color: #fff;
        text-transform: uppercase; }
    #navItems .search {
      margin-bottom: 40px; }
    #navItems .centerNav {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: fit-content; }
      #navItems .centerNav .mobile-segment, #navItems .centerNav .mobile-projects {
        display: none;
        font-size: 18px; }
      #navItems .centerNav .projects-button {
        text-transform: capitalize; }
      #navItems .centerNav.segments-open .mobile-segment {
        display: block; }
      #navItems .centerNav.projects-open .mobile-projects {
        display: block; }
      #navItems .centerNav .icoSearch {
        right: 10px; }
      #navItems .centerNav ul {
        width: auto;
        display: block;
        height: auto;
        text-align: center;
        font-size: 24px;
        font-weight: 800;
        padding-bottom: 40px; }
        #navItems .centerNav ul li {
          color: #fff;
          text-transform: uppercase; }
          #navItems .centerNav ul li a {
            color: #666666;
            line-height: 55px; } }
