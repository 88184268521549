.swal2-cancel, .swal2-confirm{
    font-size: 13px !important;
}

.swal2-close{
    color: #71CFC9 !important;
}
#profile-page{

    .container{
        width: 960px;
        margin: 0 auto 100px auto;

        @media(max-width: 1024px){
            width: 920px;
        }
        @media(max-width: 768px){
            padding: 50px 20px 0 20px;
            width: calc(100% - 40px);
        }
    }

    h1.page-title{
        font-size: 3em;
        font-weight: bold;
        text-align: center;
    }

    h3.simpleTitle{
        margin-bottom: 30px;
    }

    h1#errorMsg{
        color: red;
        margin-top: 30px;
    }

    #btn-send{
        margin-top: 20px;
    }

    #form-profile{
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;

        .button--remove-logo {
            margin-left: 0;
            font-size: 9px;
            height: fit-content;
            padding: 4px;
            margin-bottom: 15px;
        }
    
        .text-area {
            padding: 10px;
            border: 1px solid #cecece;
            border-radius: 5px;
            margin-top: 10px;
            margin-bottom: 15px;
            width: calc(100% - 22px);
        }
        
        .form-group{
            width: 45%;
            position: relative;


            &.form-group--text {
                width: 100%;
            }

            .helper {
                font-size: 11px;
                color: #888;
                margin-left: 5px;
            }
            
            &.icon-absolute svg{
                position: absolute;
                bottom: 25px;
                right: 15px;
                color: #b5b5b5
            }

            label{
                font-size: 14px;

                .asterisco{
                    color: red;
                }
            }

            input.text-field{
                padding: 10px;
                border: 1px solid #cecece;
                border-radius: 5px;
                margin-top: 10px;
                margin-bottom: 15px;
                width: calc(100% - 22px);
            }

            input.error-input{
                border-color: red;
            }
        }

        .select-countries {
            margin-bottom: 10px;
        }
    }

    .add-location {
        cursor: pointer;
        display: flex;
        text-align: left;
        align-items: center;
        margin-top: 10px;
  
        svg {
          margin-right: 3px;
          color: #87cec9;
        }
  
        span {
          color: #888;
          font-size: 11px;
        }
    }

    .auto-complete-group {
        display: flex;
        align-items: center;
        
        input{
            padding: 10px;
            border: 1px solid #cecece;
            border-radius: 5px;
            width: 45%;
            margin-bottom: 10px;
        }

        svg {
          margin-left: 3px;
          cursor: pointer;
          color: #87cec9;
        }
    }
}