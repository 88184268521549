#who-we-are-hero {
  width: 100%;
  height: 730px;
  overflow-y: hidden;

  @media (max-width: 1450px) {
    height: 730px;
  }

  padding-top: 60px;
  background-color: #272626;
  display: flex;
  justify-content: space-between;

  .left {
    flex: 1;
    color: white;
    padding-left: 10%;

    .title {
      font-size: 60px;
      max-width: 400px;
      margin-top: 60px;
      @media (max-width: 768px) {
        margin-top: 10px;
      }
    }

    .description {
      max-width: 350px;
      margin-top: 80px;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 21px;
    }
  }

  .right {
    flex: 2;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;

    .image-wrapper {
      position: absolute;
      top: 60px;
      padding: 0 40px;
      img {
        width: 100%;
        max-width: 784px;
      }
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
}
