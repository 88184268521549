#showcase {

	.slick-slider {
		position: relative;

		.prev {
			background-color: red;
		}

		.next {
			background-color: blue;
		}

		.slick-arrow {
			position: absolute;
			bottom: 0px;
		}
	
		.slick-prev,
		.slick-next {
			z-index: 5;
			position: absolute;
			top: 405px;
		}
	
		.slick-prev {
			left: 250px;
			background-image: url('../images/prev-showcase.png');
		}

		.slick-next {
			right: 229px;
			background-image: url('../images/next-showcase.png');
		}
	
		.slick-dots {
			bottom: 20px;
	
			li {
				width: 11px;
				height: 11px;
				border: 2px solid #ffffff;
				border-radius: 100%;
				box-sizing: border-box;
	
				button {
					&:before {
						display: none;
					}
				}
	
				&.slick-active {
					background: #ffffff;
				}
			}
		}

		.youtube-container {
			max-height: 417px;
		}
	}

	.youtube-container {
		max-width: 560px;
		max-height: 315px;
		min-height: 200px;
	}

	@media (max-width: 768px) {
		.youtube-container {
			width: 100%;
		}
	}

	.showcase-image--portrait {
		max-width: 300px;
		max-height: 450px;
	}

	.showcase-image--landscape {
		max-width: 500px;
		max-height: 330px;
	}

	.showcase-image--featured {
		max-width: 605px;
		max-height: 417px;
	}
}
