.brands-view-filter {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    justify-content: center;
  }

  .button {
    background: transparent;
    color: #50545f;
    border: none;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 20px;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 60px;
    border: 0.75px solid transparent;
    cursor: pointer;

    &:hover,
    &.button--active {
      border: 0.75px solid #50545f;
    }
  }

  .dropdown-link {
    position: relative;
  }

  .dropdown-link:hover {
    font-weight: bold;
    background: #ffffff;
    cursor: default;
    display: flex;

    @media (min-width: 769px) {
      .dropdown-wrapper {
        font-weight: normal;
        display: flex;
      }
    }
  }

  .dropdown-wrapper {
    font-family: "PolySans-Slim";
    position: absolute;
    width: 230px;
    top: 40px;
    left: 60px;
    display: none;
    z-index: 20;
    margin-left: -60px;
    font-size: 14px;

    &:hover {
      @media (min-width: 769px) {
        display: flex;
      }
    }

    .dropdown {
      display: flex;
      width: 100%;
      padding: 0 0 20px 0;
      background: #ffffff;
      box-sizing: border-box;
      font-size: 14px;
      color: #fff;
      align-items: center;
      flex-direction: column;
      border: 2px solid #e6e6e6;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    p {
      font-size: 12px;
      margin-left: 19px;
    }

    .separator {
      margin: 10px auto 10px auto;
      height: 2px;
      width: 50px;
      background: #fff;
      opacity: 0.8;
    }

    .dropdownSettings-menu {
      display: flex;
      width: 100%;
      flex-direction: column;
      flex: 1;
      height: 100%;

      li {
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        line-height: 40px;
        a {
          color: #b1b1b1;
        }

        &:hover {
          a {
            color: #646464;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .dropdown-link:hover {
      .dropdown-wrapper {
        display: block;
        left: 0;
        button.close {
          // width: fit-content;
          padding: 0 20px;
        }

        .dropdown {
          .dropdownSettings-menu {
            padding-bottom: 0;
          }
        }
      }
    }

    li.dropdown-link {
      height: auto;
    }

    li.dropdown-link:hover {
      color: #646464;
    }

    li.dropdown-link .dropdown-wrapper {
      padding-bottom: 15px;
      .close {
        height: 20px;
        width: 20px;
        border-radius: 10px;
        border: 2px solid #fff;
        background: transparent;
        font-size: 14px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    li.dropdown-link .openedDropdown {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
