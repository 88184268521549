@import "../../../shared/style/Base";
#contentLogin {
	width: 95%;
	height: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	.formItem {
		text-align: left;
		margin-top: 10px;
		
		&.textRight {
			text-align: right;
		}

		&.sign-in-buttons {
			display: flex;
			flex-direction: column;
			span {
				margin-bottom: 2px;
				color: #888;
				font-size: 14px;
				cursor: pointer;
			}
		}

		.button{
			align-self: center;
			margin-top: 15px;
			display: flex;
			justify-content: center;
		}
	}

	h2 {
	    color: #000;
			font-weight: 600;
	    font-size: 24px;
	    text-transform: uppercase;
			text-align: center;
			margin: 20px 0;
	}

	.button {
		@extend %BUTTON_BLACK;
		display: inline-block;
		font-weight: normal;
		font-size: 14px;

		cursor: pointer;

		&.register {
			display: block;
			width: 185px;
		}
	}

	.not-registered-box {
		display: flex;
		flex-direction: column;
		margin-bottom: auto;
		border-top: 1px solid #eee;
		align-items: center;
		margin-top: 30px;
    	padding-top: 20px;

		span {
			margin-bottom: 20px;
			color: #888;
			font-size: 14px;
		}
		.button{
			width: auto;
			padding: 0 20px;
			margin: 0;
		}
	}

}

@media (max-width: 768px){
	#contentLogin {
		padding-bottom: 20px;
		.button {
			width: auto;
		}
	}
}
