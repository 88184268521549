@import "../../../shared/style/Base";

.results {
	font-size: 11px;
	color: #656565;
	margin-left: 15px;
	font-weight: 400;
}

.info{

	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.nameAndBrands {
		font-size: 14px;
		color: #656565;
		width: 90%;
		margin-bottom: 30px;
		line-height: 20px;
		font-weight: 500;
		text-align: center;
		@extend %Montserrat;
	}
	
	.styleBorder {
		padding: 3px 5px;
		border: 1px solid #71cfc9;
		display: inline-block;
		font-size: 11px;
		margin-bottom: 25px;
		line-height: 1.3;
	
		.emph {
			color: #71cfc9;
		}
	}
}

.contentPagination {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 50px;

	@media(max-width: 768px){
		width: auto;
	}

	ul {
		display: flex;
		justify-content: center;

		li {
			margin-left: 2px;
			width: 30px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 11px;	
			color: black;
			border-radius: 50%;
			box-sizing: border-box;
			font-weight: bold;
			cursor: pointer;

			a {
				color: #808080;
			}

			&.active {
				color: #ffffff;
				background: #272626;

				a {
					color: #ffffff;
				}
			}
		}
	}

	.nextPagination, .prevPagination {
		border: none;
		font-size: 26px;
		cursor: pointer;
		color: $GREEN_LIGHT;
	}

	.nextPagination {
		margin-top: -4px;
		margin-left: 2px;
		font-size: 28px;
	}

	.prevPagination {
		margin-top: 4px;
	    transform: rotate(180deg);
	}
}
