@import "../../shared/style/Base";

.linesheet {
  width: 1020px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 10px;
  }

  .slick-dots {
		height: 50px;
		bottom: -50px;

		li {
			width: 11px;
			height: 11px;
			border: 2px solid #D8D8D8;
			border-radius: 100%;
			box-sizing: border-box;

			button {
				&:before {
					display: none;
				}
			}

			&.slick-active {
				background: #D8D8D8;
			}
		}
	}

  .containerLineSheetHome {
    width: 960px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto 100px auto;

    @media (max-width: 768px) {
      .slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .containerImage {
      width: calc(100% - 20px) !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-right: 20px;
      margin-bottom: 20px;

      @media (max-width: 768px) {
        max-width: 250px!important;
      }

      p {
        width: 100%;
        text-align: center;
        margin: 10px 0;
        text-transform: uppercase;
        color: #656565;
        font-size: 14px;
      }

      a {
        color: #656565;
      }

      .blockBrand {
        width: 100%;
        height: 250px;
        margin: 10px 0;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        a {
          width: 100%;
          height: 100%;
        }

        img {
          width: 100%;
          border: 1px solid #ccc;
				  box-sizing: border-box;
        }

        .hover {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          opacity: 0.8;
          background: #cccccc;
          display: flex;
          align-items: center;
          justify-content: center;

          a {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            opacity: 0.8;
            background: #cccccc;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        &:hover {
          .hover {
            display: none;
          }
        }
      }
    }
  }
}

#suggested {
  display: flex;
  justify-content: center;

  .containerLineSheetHome {
	width: 1000px;
	position: relative;
	overflow: hidden;
	padding-bottom: 40px;

	.contentSlider {
		width: 930px;
		margin-left: 35px;
	}

	.slick-dots {
		height: 50px;
		bottom: -50px!important;

		li {
			width: 11px;
			height: 11px;
			border: 2px solid #D8D8D8;
			border-radius: 100%;
			box-sizing: border-box;

			button {
				&:before {
					display: none;
				}
			}

			&.slick-active {
				background: #D8D8D8;
			}
		}
	}

	h2 {
		margin: 40px 0;
		text-align: center;
		font-size: 16px;
		font-weight: 300;
		text-transform: uppercase;
		color: #666;
		@extend %Montserrat;

		&:before {
			content: "";
			position: absolute;
			left: 35px;
			right: 64%;
			background: #71cfc9;
			height: 2px;
			margin-top: 8px;
		}

		&:after {
			content: "";
			position: absolute;
			left: 64%;
			right: 55px;
			background: #71cfc9;
			height: 2px;
			margin-top: 8px;
		}
	}
}
}

.centerComponent {
  .simpleTitle {
    margin: 50px 0 30px;
  }
}


@media (max-width: 768px){
  .boxBrands {
    width: calc(100% - 10px);
    padding: 0 5px;
    margin-bottom: 50px;

    .containerImage {
      width: calc(50% - 10px);
      margin:  5px;
      display: flex;
      height: auto;
      overflow: hidden;
      position: relative;


      .blockBrand {
        height: auto;
        width: 100%;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        a {
          height: auto;
        }

        img {
          max-width: 100%;
        }
      }
    }
  }

}


.home-seller{
  width: 65%;
  margin: 0 auto;
  .half-wrapper{
    display: flex;
    justify-content: center;
  }
  .half-left{
    width: 33.33333%;
    .boxBrands{
      width: 100%;
      justify-content: center;
      .containerImage{
        width: 222px;

      }
    }
  }
  .linesheet{
    width: 100%;
    .containerImage{
      width: 222px !important;
    }
    .containerLineSheetHome{
      justify-content: center;
    }
  }
  .half-right{
    width: 66.66667%;
  }
}
