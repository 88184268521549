@import "../../../shared/style/Base.scss";

#Wrapper {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  // margin-bottom: 80px;

  @media (max-width: 500px) {
    height: 80vh;
  }

  .teamHighLight {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 40px;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position-y: 100%;
    box-shadow: inset 0px -45px 27px -28px rgba(0, 0, 0, 0.15);

    .wrapperText {
      @extend %Montserrat;
      color: #fff;
      max-width: 1280px;
      margin: 60px;
      font-size: 56px;
      font-weight: 300;
      text-align: center;
      line-height: 1.5;
      text-transform: uppercase;

      @media (max-width: 500px) {
        font-size: 30px;
      }
    }
  }
}

.separator {
  margin: 60px auto 0 auto;
  width: 1px;
  height: 64px;
  background: #666666;
  opacity: 0.5;
}

.whoWeAreContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: fit-content;

  #OurStory,
  #OurTeam,
  #OurPartners {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #OurStory {
    padding-top: 55px;

    .storyBoxes {
      display: flex;

      .toTheWorldBox,
      .connectedCollaboration {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 485px;
        height: 255px;
        margin: 20px 50px 0 50px;

        &:hover {
          box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.46);
        }

        .boxTitle,
        .boxText {
          color: $FONT-COLOR;
        }

        .boxTitle {
          font-size: 1.8em;
          margin: 30px;
        }

        .boxText {
          font-size: 1.1em;
        }

        .trace {
          width: 30px;
          height: 3px;
          margin-top: 6%;
          background-color: $FONT-COLOR;
        }
      }

      .toTheWorldBox {
        background-image: url("../images/yellow.png");
      }

      .connectedCollaboration {
        background-image: url("../images/blue.png");
      }
    }
  }

  p,
  h3.title {
    text-align: center;
  }

  h3.title {
    font-size: 1.6em;
    text-transform: uppercase;
    @media (max-width: 768px) {
      font-size: 21px;
    }
  }

  p.descriptionText {
    padding: 20px 15px 50px 15px;
    line-height: 19px;
    font-size: 14px;
    color: #666666;
    max-width: 450px;
    text-align: center;
    @media (max-width: 768px) {
      padding: 20px 20px;
    }
  }

  .portraits {
    display: flex;
    justify-content: space-between;
    width: 1230px;
    height: fit-content;

    @media (max-width: 600px) {
      width: 400px;
    }

    .portrait-content {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      width: 100%;
      max-width: 1280px;
      height: fit-content;
      padding: 0 15px;
      transition: 1s;

      @media (max-width: 600px) {
        width: 370px;
      }

      .portrait {
        width: calc(25% - 20px);
        margin: 10px 0;
        position: relative;
        box-sizing: border-box;
        cursor: pointer;

        img {
          width: 95%;
          height: 100%;
        }

        &:after {
          width: 100%;
          height: 100%;
          content: "";
          position: absolute;
          right: -10px;
          bottom: -10px;
          z-index: -1;
        }

        .card {
          visibility: hidden;
          opacity: 0;
          transition: 200ms ease-in-out;
          border: 1px solid #60a697;
          box-sizing: border-box;

          .cardHeader {
            display: none;
          }

          .cardContent {
            display: none;
          }

          .cardFooter {
            display: none;
          }
        }

        &:hover {
          &:after {
            @media (min-width: 769px) {
              background: #60a697;
            }
          }

          .card {
            @media (min-width: 769px) {
              visibility: visible;
              opacity: 0.95;
              display: block;
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              background-color: #ffffff;
            }

            .cardHeader {
              @media (min-width: 769px) {
                display: block;
                padding: 30px 22px 15px 22px;
              }

              @media (max-width: 600px) {
                padding: 15px 22px 10px;
              }

              .borderCard {
                @media (min-width: 769px) {
                  width: 20px;
                  border-bottom: 4px solid #60a697;
                }
              }

              h2,
              h3 {
                text-transform: uppercase;
                @extend %Montserrat;
                color: #60a697;
              }

              h2 {
                @media (min-width: 769px) {
                  margin: 0 0 10px 0;
                  font-weight: 700;
                  font-size: 22px;
                }
              }

              h3 {
                @media (min-width: 769px) {
                  font-size: 22px;
                  line-height: 1.5;
                  margin-bottom: 5px;
                }
              }

              @media (max-width: 1200px) {
                h2 {
                  font-size: 1.3em;
                }
                h3 {
                  font-size: 1em;
                }
              }
            }

            .cardContent {
              @media (min-width: 769px) {
                display: block;
                padding: 10px 22px;
              }

              p {
                text-align: left;
                line-height: 1.5;
                font-size: 1.05em;
                margin: 0;
              }
              @media (max-width: 1200px) {
                p {
                  font-size: 0.95em;
                }
              }
              @media (max-width: 1100px) {
                p {
                  font-size: 0.9em;
                }
              }
              @media (max-width: 600px) {
                h2 {
                  font-size: 1em;
                }
                h3 {
                  font-size: 0.8em;
                }
                p {
                  font-size: 0.7em;
                  line-height: 1.3;
                }
              }
            }

            .cardFooter {
              @media (min-width: 769px) {
                display: block;
                padding: 30px 22px;
                position: absolute;
                bottom: 0;
                color: #60a697;
              }

              @media (max-width: 600px) {
                padding: 10px 22px;
              }

              h3 {
                @extend %Montserrat;
                font-weight: 500;
                font-size: 1.3em;
              }
            }
          }
        }

        .card-visible {
          visibility: visible;
          opacity: 0.95;
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background-color: #ffffff;

          .cardHeader {
            display: block;
            padding: 30px 22px 15px 22px;

            @media (max-width: 600px) {
              padding: 15px 22px 10px;
            }

            .borderCard {
              width: 20px;
              border-bottom: 4px solid #60a697;
            }

            h2,
            h3 {
              text-transform: uppercase;
              @extend %Montserrat;
              color: #60a697;
            }

            h2 {
              margin: 0 0 10px 0;
              font-weight: 700;
              font-size: 22px;
            }

            h3 {
              font-size: 22px;
              line-height: 1.5;
              margin-bottom: 5px;
            }

            @media (max-width: 1200px) {
              h2 {
                font-size: 1.3em;
              }
              h3 {
                font-size: 1em;
              }
            }
          }

          .cardContent {
            display: block;
            padding: 10px 22px;

            p {
              text-align: left;
              line-height: 1.5;
              font-size: 1.05em;
              margin: 0;
            }
            @media (max-width: 1200px) {
              p {
                font-size: 0.95em;
              }
            }
            @media (max-width: 1100px) {
              p {
                font-size: 0.9em;
              }
            }
            @media (max-width: 600px) {
              h2 {
                font-size: 1em;
              }
              h3 {
                font-size: 0.8em;
              }
              p {
                font-size: 0.7em;
                line-height: 1.3;
              }
            }
          }

          .cardFooter {
            display: block;
            padding: 30px 22px;
            position: absolute;
            bottom: 0;
            color: #60a697;

            @media (max-width: 600px) {
              padding: 10px 22px;
            }

            h3 {
              @extend %Montserrat;
              font-weight: 500;
              font-size: 1.3em;
            }
          }
        }
      }
    }
  }

  .partnersContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -120px;

    @media (max-width: 768px) {
      flex-wrap: wrap;
      margin-top: 0;
      margin-bottom: 50px;
    }

    .partner {
      flex: 1 0 21%;
      display: flex;
      justify-content: flex-start;

      @media (max-width: 768px) {
        flex-basis: 50%;
        width: 100%;
      }

      img {
        width: fit-content;
        height: fit-content;

        @media (max-width: 768px) {
          height: 100%;
          width: 100%;
          max-width: 183px;
        }
      }
    }
  }

  .section {
    margin: 80px 100px 20px 100px;
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: flex-start;
    color: #50545f;

    > .title {
      font-size: 28px;
      letter-spacing: 1px;
      color: #50545f;
      margin-bottom: 40px;
    }

    &#our-values {
      .values {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        .value {
          margin-top: 20px;
          max-width: 350px;

          &:first-child {
            padding: 0;
          }

          &:last-child {
            padding: 0;
          }

          .title {
            font-size: 12px;
            letter-spacing: 2px;
            margin-bottom: 10px;
          }

          .text {
            font-size: 14px;
            letter-spacing: 1px;
            line-height: 21px;
          }
        }
      }
    }

    &#our-partners {
      .container {
        display: flex;
        @media(max-width: 768px) {
          flex-direction: column;
        }

        font-size: 14px;
        letter-spacing: 1px;
        line-height: 21px;

        .left {
          flex: 1;
        }

        .right {
          flex: 3;
          display: flex;
        }
      }
    }

    &#find-out-more {
      margin: 0;
      margin: 80px 100px 20px 100px;

      .right {
        display: none;
      }

      padding-bottom: 40px;

      @media (min-width: 768px) {
        padding-bottom: 0;
        height: 78vh;
        .right {
          display: flex;
          justify-content: center;
          width: 100%;
        }
      }

      .container {
        display: flex;
        width: 100%;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 21px;

        .buttons {
          margin-top: 20px;
          button {
            margin-top: 10px;
            margin-right: 20px;
          }
        }

        .left {
          max-width: 392px;

          span {
            max-width: 350px;
          }
        }


        .imageContainer {
          margin-top: -180px;
          z-index: 2;
          display: flex;
          justify-content: center;
          position: relative;
          img {
            max-height: 100vh;
          }
        }
      }
    }
  }
}
