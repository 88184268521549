@import "../../style/Base";

#termsAndConditions {
  float: right;

  &.termsAndConditions--float-right {
    margin-top: 40px;
  }

  @media (max-width: 768px) {
    float: left;
    display: flex;
    justify-content: center;
    clear: both;
    width: 100%;
    padding-bottom: 0px;
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
  }

  span {
    display: none;
    @media (max-width: 768px) {
      display: inline-block;
      color: #fff;
      font-weight: 600;
      font-size: 10px;
      padding: 0 18px 0 18px;
    }
  }

  button {
    width: auto;
    background: transparent;
    height: 32px;
    border-radius: 2px;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 20px;
    padding: 0 10px;
    font-weight: normal;
    height: 30px;
    border-radius: 30px;
    width: 145px;
    font-family: $FONT-POLY;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    border: 1px solid #fff;
    display: inline-block;
    text-align: center;
    opacity: 0.8;
    margin-left: 0;

    &:last-child {
      margin-left: 10px;
    }

    @media (max-width: 768px) {
      display: flex;
      padding: 0;
      height: auto;
      color: #ffffff;
      border-radius: 0;
      font-size: 10px;
      border: none;
      display: inline-block;
      line-height: 22px;
      text-align: center;
      opacity: 1;
      box-shadow: none;
      font-weight: 600;
      margin: 0 20px;

      &:last-child {
        margin-left: 0px;
      }

      &.about {
        width: auto;
      }

      &.terms {
        width: auto;
      }
    }
  }
}

.termsOfUse,
.privacyPolicy {
  .lh-modal-container {
    height: 80%;

    margin-top: 60px;
  }
  h1 {
    text-transform: uppercase;
    text-align: center;
    font-size: 22px;
    margin: 25px 0;
    @extend %Montserrat;
  }

  h3 {
    color: #666666;
    font-family: $FONT;
    font-weight: 600;
    margin: 20px 0;
    font-size: 14px;
  }

  h2 {
    margin: 25px 0;
    font-size: 16px;
    font-weight: 800;
    color: #71cfc9;
    text-align: center;
    text-transform: uppercase;
    @extend %Montserrat;
  }

  p,
  li {
    color: #696969;
    font-size: 14px;
    line-height: 20px;
    margin: 10px 0px;
    font-family: "Polysans-Slim", Helvetica, Arial, sans-serif;
  }

  ol {
    list-style-type: decimal;
    li {
      margin: 15px 0;
    }
  }

  .contact {
    text-align: center;

    h4 {
      margin: 10px 0;
      font-weight: 600;
      text-transform: uppercase;
      color: #696969;
      font-size: 14px;
      font-family: $FONT;
    }
  }

  u {
    text-decoration: underline;
  }

  u {
    text-decoration: underline;
  }
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
}
