#filterAlphabetBuyers {
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 10px;

	.letter {
		font-size: 18px;
		color: #808080;
		padding: 5px;
		cursor: pointer;
		width: 15px;
		text-align: center;
	}

	.letter--selected {
		border-radius: 5px;
		border: 2px solid #000000;
		color: #000000;
	}

	@media (min-width: 768px) {
		margin-left: 200px;
	}
}