@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none; }

button {
  border: none;
  outline: none; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  font-family: "PolySans-Slim";
  background: #ffffff; }

@media (max-width: 768px) {
  html, body {
    max-width: 768px;
    width: 100%;
    overflow-x: hidden; } }

/* @media (max-width: 1366px) {
    %CENTER_COMPONENT {
        width: 90%;
        margin: 0 auto;
    }

    %CENTER_COMPONENT_SMALL {
        width: 80%;
        margin: 0 auto;
    }
} */
#subscribe {
  text-align: center;
  padding-bottom: 50px; }
  @media (max-width: 768px) {
    #subscribe {
      padding: 100px 0 78px 0; } }
  #subscribe h3.title {
    font-size: 24px;
    line-height: 28px; }
    @media (max-width: 768px) {
      #subscribe h3.title {
        font-size: 18px; } }
  #subscribe p.description {
    font-family: "PolySans-Slim";
    padding-top: 20px;
    line-height: 25px;
    color: #666666;
    font-size: 14px;
    line-height: 19px;
    width: 80%;
    margin: 0 auto; }
    @media (max-width: 768px) {
      #subscribe p.description {
        padding: 10px 0 0 0; } }
  #subscribe .success-message {
    margin-top: 40px;
    font-size: 24px;
    line-height: 28px; }
    @media (max-width: 768px) {
      #subscribe .success-message {
        font-size: 18px;
        margin-top: 47px; } }
    #subscribe .success-message .small-message {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #666666;
      margin-top: 35px; }
  #subscribe .error-message {
    font-size: 11px;
    margin-top: 8px;
    color: red; }
    @media (max-width: 768px) {
      #subscribe .error-message {
        font-size: 9px; } }
  #subscribe .form {
    display: flex;
    flex-flow: column;
    text-align: -webkit-center;
    width: fit-content;
    margin: 50px auto;
    position: relative; }
    @media (max-width: 768px) {
      #subscribe .form {
        width: 80%; }
        #subscribe .form::before {
          position: absolute;
          top: -17px;
          left: calc(50% - 11px);
          display: block;
          content: '';
          background-image: url("../images/mail.svg");
          width: 22px;
          height: 19px;
          background-size: 22px 19px; } }
    #subscribe .form input {
      position: relative;
      width: 390px;
      border: none;
      border-bottom: 2px solid #D9D9D9;
      padding: 18px 40px 18px 40px;
      font-size: 14px;
      background-color: transparent;
      background-size: 36px 30px; }
      #subscribe .form input:focus {
        outline: none; }
      @media (max-width: 768px) {
        #subscribe .form input {
          width: auto;
          background: none;
          text-align: center;
          font-size: 11px;
          font-weight: 400;
          padding: 15px 10px 7px 10px;
          position: relative; } }
      #subscribe .form input::placeholder {
        text-align: center;
        letter-spacing: 2px;
        font-family: "PolySans-Slim";
        color: #A7A9AF; }
    #subscribe .form button {
      font-weight: normal;
      height: 40px;
      width: 130px;
      font-family: "PolySans-Slim";
      text-transform: uppercase;
      color: #FFF;
      cursor: pointer;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-align-items: center;
      align-items: center;
      background-color: #272626;
      border-radius: 30px;
      float: left;
      margin: 20px auto;
      letter-spacing: 1.5px; }
      @media (max-width: 768px) {
        #subscribe .form button {
          margin: 85px auto; } }
