@import "../../../presentation/shared/style/Base";

#menuCollection {
  display: flex;
  width: 100%;
  margin-left: 7.5%;
  flex-wrap: wrap;

  &.justify-center {
    justify-content: center;
    margin-left: 0;
    max-width: 887px;
  }

  @media (max-width: 768px) {
    width: fit-content;
    justify-content: center;
    margin: 10px 0;
  }

  li {
    @extend %BUTTON_BLACK;
    font-size: 10px;
    letter-spacing: 2px;
    line-height: 20px;
    width: fit-content;
    padding: 0 20px;
    height: 28px;

    &:first-child {
      margin-left: 0;
    }

    @media (max-width: 768px) {
      margin: 25px;
      margin-top: 0px;

      &:first-child {
        margin: 30px;
      }
    }

    a {
      color: #ffffff;
    }
  }
}
