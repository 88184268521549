@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none; }

button {
  border: none;
  outline: none; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  font-family: "PolySans-Slim";
  background: #ffffff; }

@media (max-width: 768px) {
  html, body {
    max-width: 768px;
    width: 100%;
    overflow-x: hidden; } }

/* @media (max-width: 1366px) {
    %CENTER_COMPONENT {
        width: 90%;
        margin: 0 auto;
    }

    %CENTER_COMPONENT_SMALL {
        width: 80%;
        margin: 0 auto;
    }
} */
.variation-tooltip {
  color: #6d6d6d;
  font-weight: bold;
  box-shadow: 0px 2px 6px #99999940;
  border-radius: 5px;
  padding: 6px 10px; }
  @media (max-width: 768px) {
    .variation-tooltip {
      max-width: 50px; } }

#termsAndAboutRatings {
  padding-bottom: 20px; }
  #termsAndAboutRatings ul {
    display: flex;
    align-items: center; }
    #termsAndAboutRatings ul li {
      text-align: center;
      margin-left: 16px;
      font-size: 12px;
      color: #4A4A4A; }
      #termsAndAboutRatings ul li button {
        cursor: pointer;
        width: auto;
        font-size: 11px;
        color: #71CFC9;
        line-height: 20px;
        font-weight: 600;
        background: none;
        box-shadow: none; }
      #termsAndAboutRatings ul li:first-child {
        margin-left: 0; }
  #termsAndAboutRatings .lh-modal-wrapper {
    margin-top: 60px; }
  #termsAndAboutRatings .lh-modal-container {
    height: auto;
    max-height: 80%;
    overflow-y: scroll;
    text-transform: none; }
  #termsAndAboutRatings .lh-modal-body {
    padding-bottom: 20px !important; }
    #termsAndAboutRatings .lh-modal-body p {
      color: #696969;
      font-family: "PolySans-Slim";
      font-size: 14px;
      line-height: 20px;
      margin: 10px 0px; }
    #termsAndAboutRatings .lh-modal-body h1, #termsAndAboutRatings .lh-modal-body h2, #termsAndAboutRatings .lh-modal-body h3 {
      margin: 20px 0; }

@media (max-width: 768px) {
  #termsAndAboutRatings {
    padding-bottom: 0;
    overflow: hidden;
    padding-bottom: 20px; }
    #termsAndAboutRatings ul {
      width: 100%;
      justify-content: center; }
      #termsAndAboutRatings ul li {
        text-align: center;
        margin-left: 16px;
        font-size: 12px;
        color: #4A4A4A; }
        #termsAndAboutRatings ul li a {
          font-size: 11px;
          color: #71CFC9;
          line-height: 20px;
          font-weight: 600; }
        #termsAndAboutRatings ul li:first-child {
          margin-left: 0; } }
