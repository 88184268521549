.filterBuyersComponent {
    margin-top: 40px;
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;

    @media (min-width: 768px) {
        margin-top: -40px;
        margin-left: 0;
        margin-right: 0;
    }
}