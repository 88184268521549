.breadcomb-component {
  width: 100%;
  height: 60px;
  margin-top: 60px;
  background-color: #f1ede9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #828180;

  &.breadcomb--jumbotitle {
    margin-top: 0;
  }

  ul {
    display: flex;
    margin-left: 117px;

    @media (max-width: 768px) {
      margin-left: 15px;
      flex-wrap: wrap;
    }

    li {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 20px;
      color: #50545f;

      a {
        color: #50545f;
      }

      .arrow {
        margin: 0 5px;
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 20px;
        color: #50545f;
      }

      &:last-child .arrow {
        display: none;
      }
    }
  }
}
