@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "../../../shared/style/Base";

#landing-page {
  overflow: hidden;
  button :not(.slick) {
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
  }
  .hd-mb {
    @media (max-width: 768px) {
      display: none;
    }
  }
  .show-mb {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
  #subscribe,
  #our-retailers,
  #our-brands,
  #our-platform,
  #register,
  #about-the-project {
    padding-top: 125px;
    @media (max-width: 768px) {
      padding-top: 75px;
    }
  }

  @media (max-width: 768px) {
    #our-platform {
      .left .buttons button {
        margin-top: 15px!important;
      }
    }
  }

  #our-brands-items {
    width: 100%;
    margin-bottom: 100px;
  }

  #address {
    display: flex;
    margin-top: 30px;
    font-size: 14px;
    line-height: 17px;
    font-family: $FONT;

    p {
      width: 200px;
    }

    @media (max-width: 768px) {
      display: none;
    }
    p:nth-child(1) {
      margin-left: 60px;
    }
    p:nth-child(2) {
      margin-left: 35px;
    }
  }
  #login-register-mobile {
    display: none;

    @media (max-width: 768px) {
      padding-top: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .register {
      text-transform: uppercase;
      width: 92px;
      height: 27px;
      background: #71cfc9;
      border-radius: 8px;
      font-size: 12px;
      font-weight: 700;
      line-height: 27px;
      text-align: center;
      color: #fff;
      letter-spacing: -0, 0154px;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.49);
      cursor: pointer;
      margin-bottom: 10px;
    }
    button {
      color: #48a797;
      font-size: 12px;
      font-weight: normal;
      line-height: 17px;
      background: transparent;
      text-transform: capitalize;
    }
  }
  .container {
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 768px) {
      // padding: 0 10px;
    }

    section {
      text-align: center;

      h3.title {
        font-size: 28px;
        letter-spacing: 1px;
        line-height: 21px;
      }

      p.description {
        padding: 30px 100px 30px 100px;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 21px;
        color: #666666;
        width: 940px;
        margin: 0 auto;

        @media (max-width: 1024px) {
          width: calc(940px - 200px);
        }
        @media (max-width: 768px) {
          width: calc(100% - 40px);
          padding: 20px 20px;
        }
      }
    }

    #register {
      text-align: center;
      @media (max-width: 768px) {
        padding: 0;
      }
      .description {
        padding-bottom: 0px;
      }
      .name-input {
        font-size: 40px;
        text-align: center;
        border: none;
      }
      .who-are-you-separator {
        margin: 0 auto;
        width: 1px;
        margin-top: 32px;
        margin-bottom: 26px;
        height: 64px;

        background: #666666;
        opacity: 0.5;
      }
      .who-are-you-text {
        color: #8c8c8c;
        position: relative;
        font-weight: 600;
        font-family: $FONT;
      }

      .boxes {
        display: flex;
        justify-content: center;
        margin-top: 2em;
        @media (max-width: 768px) {
          margin: 0 20px;
          flex-direction: column;
        }
      }
    }

    #about-the-project {
      padding: 80px 100px 0 100px;

      .title {
        margin-bottom: 20px;
        font-family: $FONT-POLY;
        font-size: 28px;
        color: #50545f;
        text-align: left;
      }

      .description {
        width: 100% !important;
        padding: 0 !important;
        word-wrap: break-word;
        text-align: left;
        margin-bottom: 50px !important;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }

    #our-brands-top {
      padding: 80px 100px 0 100px;

      .title {
        margin-bottom: 20px;
        font-family: $FONT-POLY;
        font-size: 28px;
        color: #50545f;
        text-align: left;
      }
    }

    #our-brands {
      margin: 0 auto;
      padding: 80px 100px 150px 100px;
      display: flex;

      @media (max-width: 768px) {
        flex-direction: column;
        padding: 80px 50px 150px 50px;
      }

      .left {
        width: 25%;
        padding-right: 15px;

        @media (max-width: 768px) {
          width: 100%;
        }

        .title {
          margin-bottom: 20px;
          font-family: $FONT-POLY;
          font-size: 28px;
          color: #50545f;
          text-align: left;
        }

        .description {
          width: 100% !important;
          padding: 0 !important;
          word-wrap: break-word;
          text-align: left;
          margin-bottom: 50px !important;
          @media (max-width: 768px) {
            font-size: 14px;
          }
        }

        .buttons {
          button {
            letter-spacing: 2px;
            font-weight: normal;
            line-height: 20px;
            height: 40px;
            width: 130px;
            font-family: $FONT-POLY;
            text-transform: uppercase;
            color: #fff;
            cursor: pointer;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-align-items: center;
            align-items: center;
            background-color: #272626;
            border-radius: 30px;
            float: left;
            margin-right: 20px;
          }
        }
      }

      .right {
        width: 70%;
        display: flex;
        justify-content: center;

        @media (max-width: 768px) {
          width: 100%;
          margin-top: 30px;
        }

        #our-brands-items {
          margin-bottom: 0;
          width: 100%;

          @media (min-width: 768px) {
            margin-left: 100px;
          }

          .brand-slider {
            width: 100%;
            overflow: visible;

            .slick-list {
              background: red;
              // padding: 25px 32px 196px;
              // margin: 0 -32px;
              overflow: visible;
              padding: 27px 0px 180px 0px;
            }

            .slick-prev {
              left: -65px;
            }

            .slick-next {
              margin-right: -44px;
            }
          }
        }
      }
    }

    .filtrado {
      padding-top: 0 !important;
      max-width: 1150px !important;
    }

    .swiper-wrapper {
      margin: 0 auto;
    }
  }

  #divider {
    height: 1px;
    background-color: #d9d9d9;
  }

  #our-retailers {
    margin: 0 auto;
    padding: 80px 100px 50px 100px !important;
    display: flex;

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 80px 50px 150px 50px !important;
    }

    #our-retailers-items {
      margin-left: 55px;
      @media (max-width: 768px) {
        margin-left: 0;
      }
    }

    .left {
      width: 25%;
      padding-right: 15px;

      @media (max-width: 768px) {
        width: 100%;
        padding: 10px !important;
      }

      .title {
        margin-bottom: 20px;
        font-family: $FONT-POLY;
        font-size: 28px;
        color: #50545f;
        text-align: left;
      }

      .description {
        width: 100% !important;
        padding: 0 !important;
        word-wrap: break-word;
        text-align: left;
        margin-bottom: 50px !important;
        @media (max-width: 768px) {
          font-size: 14px;
          width: 100%;
        }
      }

      .buttons {
        button {
          letter-spacing: 2px;
          font-weight: normal;
          line-height: 20px;
          height: 40px;
          width: 130px;
          font-family: $FONT-POLY;
          text-transform: uppercase;
          color: #fff;
          cursor: pointer;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-align-items: center;
          align-items: center;
          background-color: #272626;
          border-radius: 30px;
          float: left;
          margin-right: 20px;
        }
      }
    }

    .right {
      width: 70%;
      display: flex;
      justify-content: center;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  #request-a-demo {
    margin: 0 auto;
    padding: 50px 50px 50px 100px !important;
    display: flex;
    background: #f1edea;

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 50px !important;
    }
    .left {
      width: 30%;
      padding-right: 15px;
      @media (max-width: 768px) {
        width: 100%;
      }

      .title {
        margin-bottom: 20px;
        font-family: $FONT-POLY;
        font-size: 28px;
        color: #50545f;
        text-align: left;
      }

      .description {
        width: 100% !important;
        padding: 0 !important;
        word-wrap: break-word;
        text-align: left;
        margin-bottom: 50px !important;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }

      .buttons {
        button {
          letter-spacing: 2px;
          font-size: 12px;
          line-height: 20px;
          height: 40px;
          width: 130px;
          font-family: $FONT-POLY;
          text-transform: uppercase;
          color: #fff;
          cursor: pointer;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-align-items: center;
          align-items: center;
          background-color: #272626;
          border-radius: 30px;
          float: left;
          margin-right: 20px;

          @media (max-width: 768px) {
            margin-top: 15px;
          }
        }
      }
    }

    .right {
      width: 70%;
      display: flex;
      justify-content: center;

      @media (max-width: 768px) {
        width: 100%;
      }

      .img-computer {
        width: 100%;
        height: 650px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media (max-width: 768px) {
          width: 100%;
          height: auto;
          margin-top: 30px;
        }

        img {
          max-width: 100%;
          height: 130%;

          @media (max-width: 768px) {
            height: auto;
            max-width: 100%;
          }
        }
      }
    }
  }
}
