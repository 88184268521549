@import url("https://fonts.googleapis.com/css?family=Open+Sans:100,300,300i,400,400i,600,600i,700,700i,800,800i");

@font-face {
  font-family: "PolySans-Slim";
  src: url(/fonts/PolySans-Slim.woff) format("woff"),
    url(/fonts/PolySans-Slim.woff2) format("woff2");
}

* {
  text-decoration: none;
  padding: 0;
  margin: 0;
  font-family: "PolySans-Slim";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}
