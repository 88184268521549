.welcome-lh-modal{
  .lh-modal-container{
    padding: 25px;
  }
  .lh-modal-body{
    @media (min-width: 768px) {
        width: 600px;

    }
    @media (min-width: 992px){
        width: 900px;
    }

  }
}

#welcome-message-modal{
  font-family: "PolySans-Slim";

  color: #333;
  .modal-title{
      line-height: 1.42857;
      font-size: 24px;
      font-family: 'Polysans-Slim', sans-serif;
      text-align: center;
      color: #333;
      font-weight: 500;
      margin-top: 0px;
  }
  .sub-title{
    margin-bottom: 20px;
    text-align: center;
  }
  p{
    color: #333;
    font-family: "PolySans-Slim";
    line-height: 20px;
    text-align: justify;
    margin: 0 0 10px;
  }
  li{
    margin-top: 0px;
    line-height: 20px;
    counter-increment: item;
    margin-bottom: 5px;
    text-align: justify;
    color: #333;
    font-family: "PolySans-Slim";
    &:before {
    margin-right: 10px;
    content: counter(item) ".";
    font-weight: bold;
    color: #71cfc9;
    display: inline-block;
    }
  }
  .lblue {
    color: #71cfc9;
  }
  b, strong {
    font-weight: bold;
  }
  .enjoy{
    margin-top: 20px;
  }
  .modal-footer {
    margin-top: 30px;
    padding: 15px 0;
    text-align: right;
    border-top: 1px solid #e5e5e5;
  }
}
