@import "../../../../shared/style/Base";

.grid2 {
	width: 100%;
	margin: 40px auto;

	.button {
		width: fit-content;
		padding: 0 15px;
	}

	.contentLinkButton {
		width: 90%;
		margin-top: 10px;

		.linkShowCase {
			display: inline-block;
			margin: 0 0 15px;
			line-height: 15px;
			@extend %LINK_UNDERLINE;
			cursor: pointer;
		}

		.buttonShowCase {
			display: block;
			@extend %BUTTON_BLUE;
		}
	}

	.containerGrid {
		width: 925px;
		display: flex;
		margin-left: 80px;

		.leftGrid {
			max-width: 630px;
			width: 100%;
			box-sizing: border-box;

			.contentLinkButton {
				.linkShowCase {
					margin-bottom: 15px;
				}
			}

			.infoComponent {
				width: 100%;
				min-height: 30px;
				margin-bottom: 42px;
			}

			.containerButtonText {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				padding-left: 74px;
			}

			p {
				margin-right: 74px;
				margin-top: 20px;
				line-height: 26px;
				font-size: 14px;
				color: #4A4A4A;
			    margin-bottom: 21px;
			}

		}

		.rightGrid {
			box-sizing: border-box;
		}
	}
}


@media (max-width: 768px){
.grid2 {
	padding: 0px 10px;
	width: calc(100% - 20px);
	.main-image {

		#showcase {
			div {
				overflow: hidden;

				img {
					width: 75%;
				}
			}
		}

	}

	.grid-title {
		position: relative;
		left: 40px;
		// top: -50px;
		margin-bottom: 15.937767px;

		&.mob {
			top: -50px;
			margin-bottom: 0px;

			&.disable-margin {
				top: 0;
			}

			>div {
				top: 0;
			}

			h2 {
				min-height: auto;
			}
		}


		h2 {
			font-size: 26px;
			line-height: 36px;
			text-align: left;
			padding-left: 20px;
			padding-top: 15px;
		}
	}

	.grid4-mobile-content {
		line-height: 26px;
		font-size: 14px;
		color: #4A4A4A;
		padding: 0 30px;

		.content-buttons {
			.read-more-link {
				margin-top: 30px;
				margin-bottom: 40px;
				text-align: center;
			}

			.link-show-case {
				font-size: 12px;
				font-weight: 600;
				line-height: 22px;
				padding: 0 0 3px 0;
				border: none;
				color: #000;

				span {
					border-bottom: 1px solid $GREEN_LIGHT;
				}
			}

			.see-products-button {
				text-align: center;
				margin-bottom: 40px;
			}
		}
	}
}
}
