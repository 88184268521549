@import "../../../shared/style/Base";
.view-size-chart-image{
  padding: 20px;
  background: #FFF;
  margin: 0 auto;
  img{
	max-width: 100%;
	display: block;
	margin: 0 auto;
  }
}
.lightBoxLookBook {
    position: fixed;
    top: 0;
	z-index: 100;

	.bgLightBox {
		background: #F9F9F9;
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		right: 0;
		opacity: 0.9;
		z-index: 12;
	}

	.containerLightBox {
		width: 580px;
		height: 700px;
		padding: 20px;
		position: fixed;
		margin: -350px 0 0 -290px;
		left: 50%;
		top: 50%;
		z-index: 14;

		.containerImageLightBox {
			height: 90%;
			overflow-y: scroll;
		}

		.imageLightBox {
			text-align: center;
		}

		.containerText {
			p {
				font-size: 14px;
				margin: 25px 0;

				strong {
					font-weight: bold;
				}

				i {
					font-style: italic;
				}

				.email {
				    color: #337ab7;
				    text-decoration: none;
				}
			}
		}

		.btnLightBox {
		    margin-top: 25px;
		    margin-bottom: 15px;
		    padding: 10px 10px;
		    font-size: 18px;
		    font-weight: 700;
		    text-transform: uppercase;
		    color: #fff;
		    border: 0;
		    background: $GREEN_LIGHT;
		    transition: background-color 0.05s linear;
		}

		.topLightBox {
			width: 100%;
			color: #ffffff;
			max-width: 1280px;
			margin: 0 auto;
			position: relative;
			display: flex;
			align-items: center;
			@extend %Montserrat;

			.titleBox {
				font-size: 24px;
			    font-weight: 400;
			    color: #fbfbfb;
			    text-transform: uppercase;
				margin-bottom: 20px;

				span {
				    font-size: 18px;
					display: block;
					margin-top: 5px;
				}
			}
		}

		.blockLightBox {
			width: 100%;
			margin: 0 auto;
		}

		.closed {
			font-size: 22px;
			position: absolute;
			top: 0;
			right: -350px;
		    width: 35px;
		    height: 35px;
		    outline: 0;
		    color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}
	}

	.slick-next {
		right: -50px;
	}

	.slick-prev {
		left: -40px;
	}
}
