@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none; }

button {
  border: none;
  outline: none; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  font-family: "PolySans-Slim";
  background: #ffffff; }

@media (max-width: 768px) {
  html, body {
    max-width: 768px;
    width: 100%;
    overflow-x: hidden; } }

/* @media (max-width: 1366px) {
    %CENTER_COMPONENT {
        width: 90%;
        margin: 0 auto;
    }

    %CENTER_COMPONENT_SMALL {
        width: 80%;
        margin: 0 auto;
    }
} */
.variation-tooltip {
  color: #6d6d6d;
  font-weight: bold;
  box-shadow: 0px 2px 6px #99999940;
  border-radius: 5px;
  padding: 6px 10px; }
  @media (max-width: 768px) {
    .variation-tooltip {
      max-width: 50px; } }

.orderCartAuthentication .logoutSeller {
  display: block;
  font-size: 10px !important;
  font-weight: 100;
  text-transform: uppercase;
  color: #666666;
  cursor: pointer;
  line-height: 70px;
  height: 70px; }

.orderCartAuthentication ul li {
  cursor: pointer; }

.orderCartAuthentication ul .authentication {
  display: flex;
  margin-left: 22px;
  flex-wrap: wrap;
  padding-right: 100px; }
  .orderCartAuthentication ul .authentication img {
    margin-right: 25px; }
  .orderCartAuthentication ul .authentication span {
    cursor: pointer; }

.containerLogin {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  position: relative; }
  .containerLogin .settings {
    position: relative;
    height: 70px;
    display: flex;
    align-items: center; }
    .containerLogin .settings .cart {
      position: relative; }
      .containerLogin .settings .cart .productCount {
        position: absolute;
        top: 4px;
        right: 17px;
        min-width: 8px;
        min-height: 8px;
        background: #66dad1;
        color: #ffffff;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        padding: 3px;
        align-items: center;
        font-size: 10px !important; }
      .containerLogin .settings .cart .menu-item--sub {
        width: 250px;
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 20px; }
        .containerLogin .settings .cart .menu-item--sub li {
          border-top: none; }
      .containerLogin .settings .cart .item-added-notification {
        cursor: default;
        text-align: center;
        width: 200px;
        margin: auto;
        border-bottom: 1px solid #d9d9d9;
        color: #50545f !important;
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 20px; }
        .containerLogin .settings .cart .item-added-notification:hover {
          color: #50545f !important; }
    .containerLogin .settings .menu-item:hover .menu-item--sub {
      display: block !important; }
    .containerLogin .settings .menu-item--sub {
      display: none !important;
      position: absolute;
      right: 5px;
      top: 50px;
      background-color: white;
      z-index: 11;
      width: 250px;
      height: auto !important;
      display: flex;
      flex-direction: column;
      filter: drop-shadow(2.121px 2.121px 2px rgba(0, 0, 0, 0.2));
      border: 1px solid #d9d9d9; }
      .containerLogin .settings .menu-item--sub.menu-item--sub--with-content {
        width: 400px; }
      .containerLogin .settings .menu-item--sub .notification {
        display: none; }
      .containerLogin .settings .menu-item--sub.menu-item--sub--show-notification {
        display: block !important; }
        .containerLogin .settings .menu-item--sub.menu-item--sub--show-notification .notification {
          display: block; }
      .containerLogin .settings .menu-item--sub .top-arrow,
      .containerLogin .settings .menu-item--sub .top-arrow--border {
        position: absolute;
        top: -10px;
        right: 20px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #ffffff transparent; }
      .containerLogin .settings .menu-item--sub .top-arrow--border {
        top: -12px;
        right: 19px;
        border-width: 0 11px 12px 11px;
        border-color: transparent transparent #d9d9d9 transparent; }
      .containerLogin .settings .menu-item--sub li {
        height: auto !important;
        width: 100%;
        border-top: 1px solid #e6e6e6; }
        .containerLogin .settings .menu-item--sub li:first-child {
          border-top: none !important; }
      .containerLogin .settings .menu-item--sub:hover {
        display: block !important; }
      .containerLogin .settings .menu-item--sub .button-see-all {
        display: flex;
        justify-content: center; }
        .containerLogin .settings .menu-item--sub .button-see-all a {
          font-size: 12px;
          letter-spacing: 2px;
          color: #b3b3b3 !important; }
    .containerLogin .settings .messages {
      position: relative; }
      .containerLogin .settings .messages .messagesCount {
        position: absolute;
        top: 4px;
        right: 17px;
        min-width: 8px;
        min-height: 8px;
        background: #66dad1;
        color: #ffffff;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        padding: 3px;
        align-items: center;
        font-size: 10px !important; }
      .containerLogin .settings .messages .messages-menu .messages-block-component {
        padding: 5px; }
        .containerLogin .settings .messages .messages-menu .messages-block-component.messages-block-component--active {
          background-color: transparent; }
        .containerLogin .settings .messages .messages-menu .messages-block-component .left {
          display: block !important; }
          .containerLogin .settings .messages .messages-menu .messages-block-component .left .name {
            margin-bottom: 0; }
        .containerLogin .settings .messages .messages-menu .messages-block-component .dates .time,
        .containerLogin .settings .messages .messages-menu .messages-block-component .dates .date {
          justify-content: flex-start;
          align-items: center; }
    .containerLogin .settings .connections {
      position: relative; }
      .containerLogin .settings .connections:hover .connections-menu {
        display: block !important; }
      .containerLogin .settings .connections .connectionsCount {
        position: absolute;
        top: 4px;
        right: 20px;
        min-width: 8px;
        min-height: 8px;
        background: #66dad1;
        color: #ffffff;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        padding: 3px;
        align-items: center;
        font-size: 10px !important; }
    .containerLogin .settings .dropdownSettings-wrapper {
      font-family: "PolySans-Slim";
      position: absolute;
      width: 280px;
      top: 35px;
      right: -20px;
      display: none;
      padding-top: 20px; }
      .containerLogin .settings .dropdownSettings-wrapper:hover {
        display: flex; }
      .containerLogin .settings .dropdownSettings-wrapper .dropdownSettings {
        display: flex;
        width: 100%;
        padding: 20px 0;
        border: 1px solid #e5e5e5;
        background: white;
        box-sizing: border-box;
        font-size: 18px;
        color: #b3b3b3;
        align-items: center;
        flex-direction: column;
        border-radius: 15px;
        border-top-right-radius: 0; }
      .containerLogin .settings .dropdownSettings-wrapper p {
        font-size: 12px;
        margin-left: 19px; }
      .containerLogin .settings .dropdownSettings-wrapper .hello {
        display: flex;
        text-align: center;
        font-weight: 600;
        text-transform: uppercase; }
        .containerLogin .settings .dropdownSettings-wrapper .hello small {
          display: block;
          font-weight: 300; }
      .containerLogin .settings .dropdownSettings-wrapper .separator {
        margin: 30px auto 15px auto;
        height: 2px;
        width: 100px;
        background: #fff; }
      .containerLogin .settings .dropdownSettings-wrapper .dropdownSettings-menu {
        display: flex;
        width: 100%;
        height: auto !important;
        flex-direction: column; }
        .containerLogin .settings .dropdownSettings-wrapper .dropdownSettings-menu li {
          width: 100%;
          padding: 15px 0;
          text-align: center;
          text-transform: uppercase; }
          .containerLogin .settings .dropdownSettings-wrapper .dropdownSettings-menu li a {
            color: #b3b3b3 !important; }
          .containerLogin .settings .dropdownSettings-wrapper .dropdownSettings-menu li:hover a {
            color: #666666 !important; }
      .containerLogin .settings .dropdownSettings-wrapper .logout {
        display: flex;
        justify-content: center;
        margin: 20px auto 0 auto; }
        .containerLogin .settings .dropdownSettings-wrapper .logout .button {
          background-color: transparent;
          border: 2px solid #b3b3b3;
          color: #b3b3b3;
          font-weight: 600;
          letter-spacing: 2px; }
          .containerLogin .settings .dropdownSettings-wrapper .logout .button:hover {
            background-color: #b3b3b3;
            color: white; }
  .containerLogin .user {
    line-height: 70px;
    height: 100%;
    text-align: center;
    width: 100%; }
    .containerLogin .user:hover ~ .dropdownSettings-wrapper {
      display: flex; }
