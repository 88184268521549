.containerFeedBack {
  width: 960px;
  padding: 0 10px 0 65px;
  position: fixed;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #ccc;
  color: #666;
  overflow: hidden;
  top: 65px;
  left: calc(50% - 480px);
  justify-content: space-between;
  z-index: 10;
  font-size: 14px;
  border-radius: 15px;

  @media (max-width: 768px) {
    width: 100%;
    left: 0;
    justify-content: space-around;
    padding: 0;
    font-size: 12px;
    height: 90px;
    border-radius: 0;
  }

  .text {
    @media (max-width: 500px) {
      width: 60%;
    }
  }

  .button {
    font-weight: normal;
    height: 40px;
    width: 130px;
    font-family: "PolySans-Slim";
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #272626;
    border-radius: 30px;
    float: left;
    margin-right: 20px;
    letter-spacing: 2px;

    @media (max-width: 768px) {
      font-size: 12px;
      height: 30px;
      width: 100px;
    }
  }

  .umbrella {
    position: absolute;
    left: 0;
    bottom: -2px;

    @media (max-width: 500px) {
      display: none;
    }
  }
  .product-name {
    text-transform: uppercase;
    font-weight: 600;
  }
  .here {
    a {
      color: #71cfc9;
      font-weight: 700;
    }
  }
}
