#messages-filter {
  margin-left: 5px;
  margin-top: 90px;
  width: 100%;
  max-width: 986px;

  #buttons {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;
    flex-wrap: wrap;

    .title {
      font-size: 36px;
      letter-spacing: 1px;
      line-height: 30px;
      color: #50545f;
      margin-right: 40px;
    }

    .button {
      margin: 0 5px 0 0;
      padding: 0 20px;
      width: fit-content;
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 20px;
    }

    @media (max-width: 768px) {
      .title {
        margin-top: 20px;
        margin-bottom: 30px;
      }
      .button {
        margin-top: 10px;
      }
    }

    // button {
    //   background: transparent;
    //   border: 2px solid #636363;
    //   color: #636363;
    // }

    // .button--active {
    //   background: #636363;
    //   color: white;
    // }
  }
}
