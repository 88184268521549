@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none; }

button {
  border: none;
  outline: none; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  font-family: "PolySans-Slim";
  background: #ffffff; }

@media (max-width: 768px) {
  html, body {
    max-width: 768px;
    width: 100%;
    overflow-x: hidden; } }

/* @media (max-width: 1366px) {
    %CENTER_COMPONENT {
        width: 90%;
        margin: 0 auto;
    }

    %CENTER_COMPONENT_SMALL {
        width: 80%;
        margin: 0 auto;
    }
} */
.variation-tooltip {
  color: #6d6d6d;
  font-weight: bold;
  box-shadow: 0px 2px 6px #99999940;
  border-radius: 5px;
  padding: 6px 10px; }
  @media (max-width: 768px) {
    .variation-tooltip {
      max-width: 50px; } }

#HeaderHighLightF {
  margin-top: 60px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 640px;
  overflow: hidden; }
  @media (max-width: 1360px) {
    #HeaderHighLightF {
      height: 600px; } }
  @media (max-width: 1200px) {
    #HeaderHighLightF {
      height: 550px; } }
  @media (max-width: 1024px) {
    #HeaderHighLightF {
      height: 500px; } }
  @media (max-width: 768px) {
    #HeaderHighLightF {
      margin-top: 58px;
      height: 500px;
      overflow: hidden;
      background-color: white; } }
  #HeaderHighLightF .texture {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0px -45px 27px -28px rgba(0, 0, 0, 0.15); }
  #HeaderHighLightF .texture2 {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0px -45px 27px -28px rgba(0, 0, 0, 0.15); }
  #HeaderHighLightF .logoAndText {
    width: 520px;
    z-index: 3; }
    @media (min-width: 768px) {
      #HeaderHighLightF .logoAndText {
        margin-right: 20px; } }
    #HeaderHighLightF .logoAndText h1 {
      font-family: "PolySans-Slim";
      font-size: 60px;
      line-height: 60px;
      margin-bottom: 50px;
      color: #50545f; }
      @media (max-width: 1366px) {
        #HeaderHighLightF .logoAndText h1 {
          font-size: 30px;
          line-height: 30px; } }
    #HeaderHighLightF .logoAndText p {
      width: 343px;
      color: #50545f;
      font-family: "PolySans-Slim";
      font-weight: 300;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 21px; }
    #HeaderHighLightF .logoAndText .buttons {
      margin-top: 45px;
      display: flex; }
      @media (max-width: 768px) {
        #HeaderHighLightF .logoAndText .buttons {
          display: none; } }
      #HeaderHighLightF .logoAndText .buttons .link {
        font-size: 12px;
        line-height: 20px;
        font-size: 12px;
        font-weight: normal;
        font-family: "PolySans-Slim";
        text-transform: uppercase;
        height: 40px;
        width: 130px;
        color: #fff;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #272626;
        border-radius: 30px; }
        #HeaderHighLightF .logoAndText .buttons .link.login {
          width: 144px; }
        #HeaderHighLightF .logoAndText .buttons .link.register {
          color: #ffffff;
          margin-right: 20px; }
      @media (max-width: 1680px) {
        #HeaderHighLightF .logoAndText .buttons {
          margin-top: 40px; } }
      @media (max-width: 1366px) {
        #HeaderHighLightF .logoAndText .buttons {
          margin-top: 35px; } }
      @media (max-width: 1200px) {
        #HeaderHighLightF .logoAndText .buttons {
          margin-top: 30px; } }
    @media (max-width: 1920px) {
      #HeaderHighLightF .logoAndText {
        margin-left: 88px; } }
    @media (max-width: 1680px) {
      #HeaderHighLightF .logoAndText {
        margin-left: 88px; } }
    @media (max-width: 768px) {
      #HeaderHighLightF .logoAndText {
        width: 50%; } }
    @media (max-width: 500px) {
      #HeaderHighLightF .logoAndText {
        margin-top: 30px;
        margin-left: 30px;
        z-index: 99; } }
  #HeaderHighLightF .headerImages {
    width: 100%;
    height: 100%;
    z-index: 6;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat; }
    @media (max-width: 768px) {
      #HeaderHighLightF .headerImages {
        width: 200%;
        position: absolute;
        right: 0;
        background-position-x: calc(100vw);
        background-size: cover; }
        #HeaderHighLightF .headerImages:after {
          content: "\A";
          position: absolute;
          background: white;
          opacity: 0.5;
          width: 100%;
          height: 100%; } }
    #HeaderHighLightF .headerImages .girl {
      width: 780px;
      height: 100%;
      z-index: 5;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: flex-start; }
      @media (max-width: 1024px) {
        #HeaderHighLightF .headerImages .girl {
          justify-content: center; } }
      @media (max-width: 768px) {
        #HeaderHighLightF .headerImages .girl {
          height: 80%;
          width: auto; } }
      #HeaderHighLightF .headerImages .girl img {
        height: 100%;
        width: auto;
        position: relative; }
    #HeaderHighLightF .headerImages .croppedImages {
      display: flex;
      flex-direction: column;
      position: absolute;
      height: 100%;
      width: 100%;
      right: 0;
      align-items: flex-end; }
      @media (max-width: 1024px) {
        #HeaderHighLightF .headerImages .croppedImages {
          display: none; } }
      #HeaderHighLightF .headerImages .croppedImages .up {
        height: 250px;
        width: 400px;
        position: absolute;
        top: 0%; }
        #HeaderHighLightF .headerImages .croppedImages .up img {
          height: 100%;
          width: 100%; }
      #HeaderHighLightF .headerImages .croppedImages .down {
        height: 400px;
        width: 400px;
        position: absolute;
        bottom: -10%; }
        #HeaderHighLightF .headerImages .croppedImages .down img {
          height: 100%;
          width: 100%; }
