@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.slide {
	width: 100%;

	img {
		width: 100%;
	}
}

.slick-arrow {
	width: 30px;
	height: 40px;
	top: calc(52% - 30px);
	text-indent: 9999px;

}

.slick-prev {
	// left: -30px;
	@media(max-width: 500px){
		left: 10px;
		opacity: 1 !important;
	}
	background: url("../images/slick-prev.png") no-repeat;
	&.slick-disabled{
		opacity: 0;
	}
	&:hover {
		background: url("../images/slick-prev.png") no-repeat;
	}
	&:focus{
		background: url("../images/slick-prev.png") no-repeat;
	}
}

.slick-next {
	background: url("../images/slick-next.png") no-repeat;
	&.slick-disabled{
		opacity: 0;
	}
	&:hover {
		background: url("../images/slick-next.png") no-repeat;
	}
	&:focus{
		background: url("../images/slick-next.png") no-repeat;
	}
}


@media (max-width: 768px){

	#sliderBrands {
		position: relative;
		&:after {
		    content: "";
		    display: block;
		    box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.12);
		    width: 100%;
		    height: 20px;
		    box-sizing: border-box;
		}
		.centerComponent {
			overflow-x: auto;
			overflow-y: hidden;
			-webkit-overflow-scrolling: touch;
			padding-bottom: 40px;
			padding-right: 40px;

			.slide {
				// width: 999em;
				padding-left: 40px;

				.contentBox {
					float: left;
				}
			}
		}

		.boxComponent {
			width: 150px;
			height: 150px;
			display: block;
			float: left;

			&.breakLine {
				clear: left;
			}

			img {
				max-width: 100%;
				width: auto;
				height: 150px;
			}

			.titleBoxComponent {
				width: calc(100% - 30px);
				margin: 0 auto;
				position: relative;
				left: 0;
				bottom: 0;
				top: -30px;
				box-shadow: none;

				.buttonBox {
					display: none;
				}
				span {
					font-size: 10px;
					letter-spacing: 2.22px;
					line-height: 12px;
					margin: 8px 0;
				}
			}
		}
	}
}

.slide {
	&.template-2{
		.box-big{
			.boxComponent{
				a{
					width: 100%;
				}
				img{
					height: 600px;
				}
				&.effect{
					height: 600px;
					a{
						width: 100%;
						height: 600px;
						margin-top: -30px;
					}
					img{
						width: 540px;
						height: 540px;
					}
				}
			}
		}
		.boxes-small{
			display: flex !important;
			flex-wrap: wrap;
		}
		.box-small{
			@media(min-width: 769px){
				width: calc(50% - 15px);
			}
		}
	}
	&.template-3{
		.boxComponent{
			a{
				width: 100%;
			}
			img{
				height: 600px;
			}
		}
	}
}
