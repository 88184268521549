@import "../../../../presentation/shared/style/Base";

#backBrands {
	line-height: 15px;
	margin-left: 73px;

	a {
		@extend %LINK_UNDERLINE;
	}
}

@media (max-width: 768px){
	
	#backBrands {
		margin-left: 0px;
		a {
			color: $GREEN_LIGHT;
			border: none;
			font-size: 10px;
			line-height: 22px;
			text-transform: lowercase;
			span {
				border-bottom: 1px solid $GREEN_LIGHT;
			}
		}
	}
}