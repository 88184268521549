@import "../../../shared/style/Base.scss";
@import "../../../shared/style/Mixins.scss";

.button {
    display: inline-block;
	cursor: pointer;
    @extend %BUTTON_BLACK;
}

@media (max-width: 768px) {
	.button {
		height: 34px;
		font-size: 14px;
		font-weight: normal;
		padding: 0 20px;
	}
}