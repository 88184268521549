.buyer-page-view-component {
  .logo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    img {
      width: fit-content;
      max-width: 80%;
      max-height: fit-content;
    }
  }

  .connections-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .social-icons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 70px;
  }

  .jumboTitle {
    text-transform: uppercase;
  }

  .year-location-container {
    display: flex;
    justify-content: space-between;

    &.year-location-container--year-only {
      justify-content: center;
      .year-established {
        justify-content: center;
        span {
          text-align: center;
        }
      }
    }

    &.year-location-container--multiline {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .LocationsComponent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .year-established.year-established--end {
        justify-content: center;
        margin-left: 10px;
        margin-top: 40px;
        text-align: center;
      }
    }

    .year-established {
      display: flex;
      justify-content: flex-end;
      width: 50%;

      &.year-established--end {
        justify-content: flex-end;
      }

      .title {
        font-size: 14px;
        text-transform: uppercase;
        color: #b3b3b3;
      }

      .year {
        font-size: 20px;
        color: #6e6e6e;
      }
    }
  }

  .buyer-text {
    display: flex;
    justify-content: center;
    font-size: 21px;
    color: #666666;
    padding-left: 15px;
    margin-top: 30px;
    margin-bottom: 100px;
  }
}
