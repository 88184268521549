#order {
  display: grid;
  grid-template-columns: 30% 70%;
  padding-left: 15%;
  padding-right: 15%;
  margin: 40px 0 0 0;
  min-height: calc(100vh - 308px);
  background-color: #f1edea;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    border: 0;
  }

  b {
    font-weight: bold;
  }
  .no-orders {
    font-weight: 700;
    text-align: center;
    margin: 50px;
  }
  .order-brands {
    position: relative;
    background-color: white;
    height: fit-content;
    margin-bottom: 20px;
    max-height: 600px;
    overflow-y: auto;

    @media (max-width: 768px) {
      width: 100%;
      margin: 0 0 15px 0;
    }

    .idOrder {
      position: absolute;
      bottom: 5px;
      color: #999;
      right: 10px;
      font-size: 12px;
      text-transform: uppercase;
    }

    .order-box-title {
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 100;
      text-align: center;
      line-height: 52px;
      color: white;

      .tab {
        width: 50%;
        height: 52px;
        border-top: 1px solid #e5e5e5;
        border-bottom: none;
        background-color: white;
        color: #666;
        box-sizing: border-box;
        cursor: pointer;
        outline: 0;

        &:first-child {
          border-top-left-radius: 5px;
          border-left: 1px solid #e5e5e5;
          border-right: 1px solid #e5e5e5;
        }

        &:last-child {
          border-top-right-radius: 5px;
          border-right: 1px solid #e5e5e5;
        }

        &.active {
          border-bottom: 3px solid #71cfc9;
        }
      }
    }
    .load-more {
      height: 80px;
      width: 100%;
      margin-top: 20px;
      background: #71cfc9;
      color: #fbfbfb;
      font-size: 16px;
      border: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    .loader {
      border: 2px solid #f3f3f3;
      border-top: 2px solid #53a898;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin-left: 10px;
      animation: spin 1s linear infinite;
    }

    .no-more-orders {
      text-align: center;
      margin-top: 20px;
      font-weight: 700;
      color: #6d6d6d;
      font-size: 14px;
      margin-bottom: 20px;
    }
    .brand {
      color: #666;
      display: flex;
      height: 100px;
      position: relative;
      flex-direction: column;
      border-top: 1px solid #dbdbdb;

      &:first-child {
        border-top: none;
      }
      cursor: pointer;
      justify-content: space-between;
      box-sizing: border-box;
      margin: 5px;

      &.active {
        position: relative;
        color: white;
        span,
        div,
        .brand-name {
          z-index: 2;
          color: white;
          &.brand-name--buyer {
            text-transform: uppercase;
          }
        }

      }

      &.active:before {
        content: "\A";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #66dad1;
        padding: 5px;
        margin: -5px;
      }

      .brand-order-info {
        display: flex;
        flex-direction: column;
      }
      .order-status {
        font-size: 10px;
        margin-top: 5px;
        line-height: 14px;
        font-weight: 400;
        text-transform: uppercase;
        position: relative;

        &:after {
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          position: absolute;
          right: 0;
          top: 0;
          background-color: #dbdbdb;
          box-shadow: #dbdbdb 0 0 2px 1px;
        }
      }
      .status-gray {
        &:after {
          background-color: #999;
          box-shadow: #999 0 0 2px 1px;
        }
      }
      .status-green {
        &:after {
          background-color: #71cfc9;
          box-shadow: #71cfc9 0 0 2px 1px;
        }
      }
      .status-red {
        &:after {
          background-color: #d95c5c;
          box-shadow: #d95c5c 0 0 2px 1px;
        }
      }
      .brand-name {
        font-family: Montserrat;
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 20px;
        margin-top: 5px;
        text-transform: uppercase;
      }

      .brand-item-statistic {
        margin-top: 15px;
        color: #6d6d6d;
        font-size: 12px;
        margin-bottom: 5px;

        strong {
          font-weight: bold;
        }
      }

      .brand-item-date {
        color: #6d6d6d;
        font-size: 12px;
      }
    }
  }

  .order-button {
    text-transform: uppercase;
    border: 0;
    height: 30px;
    width: auto;
    padding: 0 15px;
    color: #fff;
    font-size: 12px;
    color: #d8d8d8;
    font-weight: 700;
    background: #c0c0c0;
    transition: background-color 0.05s;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 500px) {
      height: auto;
      line-height: 15px;
      padding: 10px 20px;
    }
  }

  .order-details {
    margin-left: 10px;
    background-color: white;

    @media (max-width: 500px) {
      margin-left: 0;
      border: none;
      width: 100vw;
    }

    .header {
      position: relative;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 20px;
      font-size: 36px;
      color: #666666;
      margin: 20px 0;

      .order-button {
        position: absolute;
        top: 11px;
        font-weight: 700;
        float: right;
        right: 11px;
        width: auto;
        padding: 0 10px;
        color: #d8d8d8;
        background: #c0c0c0;
      }
    }

    .order-resume {
      padding: 20px 15px;
      margin-top: 15px;

      @media (max-width: 500px) {
        padding: 0;
        border: 0;
        width: 100%;
      }

      .days-to-ship {
        display: flex;
        justify-content: flex-start!important;
        color: #d8d8d8;
        font-size: 12px;
        font-weight: 700;
        line-height: 17px;

        .order-button {
          color: #fff;
          margin: 0 5px 10px 5px;
        }
      }

      .header-resume {
        border-radius: 5px;
        background: white;
        color: #6d6d6d;
        margin-bottom: 20px;
        padding: 20px;

        .buyer-name {
          text-transform: uppercase;
        }

        > div {
          display: flex;
          justify-content: space-between;
        }

        h4 {
          margin: 0;
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
        }

        h2 {
          text-transform: uppercase;
          margin: 0;
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
        }

        span {
          margin: 0;
          font-size: 10px;
          font-weight: 400;
          line-height: 14px;
        }
        .border {
          width: 40px;
          height: 3px;
          background: #71cfc9;
          margin-top: 5px;
          margin-bottom: 20px;
        }
        .order-values {
          margin: 10px 0;
          font-size: 12px;
          color: #6d6d6d;
          line-height: 20px;

          @media (max-width: 500px) {
            flex-direction: column;
          }
          .total {
            text-align: right;

            @media (max-width: 500px) {
              text-align: left;
            }
          }
        }
        .order-note-wrapper {
          display: block;
          p {
            margin: 10px 0;
            font-size: 10px;
            font-weight: 400;
            line-height: 11px;
          }
        }
        .order-note {
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          color: #d8d8d8;
          text-transform: uppercase;
        }
      }

      .messages-box {
        margin-top: 15px;

        h3.title {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          border-bottom: 1px solid #e5e5e5;
          color: #666;
        }

        .message {
          margin-top: 10px;
          padding-left: 100px;
          border-bottom: 1px solid #e5e5e5;
          color: #666;

          .buyer-name {
            text-transform: uppercase;
          }

          h3 {
            font-size: 12px;
            text-transform: none;
            font-weight: 400;
            line-height: 17px;
            margin: 0;
          }

          h4 {
            margin: 0;
            font-size: 10px;
            font-weight: 100;
            line-height: 14px;
          }

          p {
            font-size: 10px;
            font-weight: 400;
            line-height: 11px;
            margin-top: 15px;
            padding-bottom: 30px;
          }
        }
      }

      .message-control {
        display: flex;
        margin-top: 20px;

        input {
          height: 28px;
          border: 2px solid #e5e5e5;
          border-radius: 5px;
          margin-right: 5px;
          -ms-flex: 2 1 auto;
          flex: 2 1 auto;
          padding: 0 10px;
        }

        button {
          width: 140px;
          height: 30px;
        }

        .sending {
          color: #dbdbdb;
          border-color: #dbdbdb;
          display: flex;
          justify-content: center;
        }
      }
    }
    .order-status {
      margin: 50px 0;
      padding: 0 15px;
      display: flex;
      justify-content: center;
      align-items: baseline;

      .status-container {
        .circle {
          margin: 0 auto;
          position: relative;
          border: 1px solid #71cfc9;
          border-radius: 40px;
          background-color: #71cfc9;
          background-clip: content-box;
          padding: 5px;
          width: 60px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;

          .inner {
            width: 25px;
            height: 25px;
            font-size: 12px;
            font-weight: 700;
            line-height: 17px;
            color: #71cfc9;
            text-align: center;
            background-color: #fbfbfb;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              font-size: 12px;
              font-weight: 700 !important;
            }
          }
        }

        .description {
          margin-top: 10px;
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          color: #666;
          text-align: center;
        }
      }

      .disabled {
        background-color: #c0c0c0 !important;
        color: #fbfbfb;
        border: #fbfbfb !important;
      }

      .order-separator {
        background-color: #71cfc9;
        height: 12px;
        flex: 2 1 auto;
        margin: 0;
      }
    }
  }

  .order-pagination {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 20px;
    font-weight: bold;
    color: black;

    .prev {
      margin-right: 10px;
      cursor: pointer;
    }

    .next {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  @media (min-width: 768px) {
    .order-pagination {
      display: none;
    }
  }
}
